import { Breadcrumb, Button, Popconfirm, Space, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { MultiFetcher } from '../../utilities/MultiFetcher';
import { withAuthenticationRequired } from '@auth0/auth0-react';

interface State {
    jobs: any;
  }

export const JobList = withAuthenticationRequired(() => { 

    const history = useHistory();
    
    const [state, setState] = useState<State>({
        jobs: []
      });

    const {fetchMultiple: fetchJobs, deleteSingle: deleteJob, isLoading} = MultiFetcher('job');   

    const fetchData = useCallback(async () => {
        const jobs = await fetchJobs({disableCache: true});
        setState(prevState => ({ ...prevState, jobs }));

        //console.log(`data: ${JSON.stringify(newsItems)}`); 
    }, [fetchJobs]);

    useEffect(() => {
        window.scrollTo(0, 0);

        fetchData();

    }, [fetchData]);

    const deleteItem = async (id: string) => {
        deleteJob(id).then(async () => await fetchData());
    }

    return (
        <React.Fragment>
            
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item>Admin</Breadcrumb.Item>
                <Breadcrumb.Item>Jobs</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ width: '80%', margin: 'auto', marginBottom: '25px'}}>

                <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px'}} className={'ant-card-head-title'}>Jobs</div>

                <Table 
                    loading={isLoading}
                    rowKey={'id'}
                    columns={[
                        // {
                        //     title: 'Id',
                        //     dataIndex: 'id',
                        //     key: 'id',
                        // },
                        {
                            title: 'Date',
                            dataIndex: 'date',
                            key: 'date',
                            render: (date) => <span>{moment(date).format('DD-MM-YYYY')}</span>
                        },
                        {
                            title: 'Title',
                            dataIndex: 'title',
                            key: 'title',
                        },
                        {
                            title: 'Place of Work',
                            dataIndex: 'placeOfWork',
                            key: 'placeOfWork',
                        },
                        {
                            title: 'Number of Hours',
                            dataIndex: 'numberOfHours',
                            key: 'numberOfHours',
                        },
                        {
                            title: 'Work & Think level',
                            dataIndex: 'workThinkLevel',
                            key: 'workThinkLevel',
                        },
                        {
                            title: 'Field of Work',
                            dataIndex: 'fieldOfWork',
                            key: 'fieldOfWork',
                        },
                        {
                            key: 'actions',
                            align: 'right',
                            render: (record: any) => 
                                <Space>
                                    <Button size='small' icon={<EditOutlined />} title='Edit' onClick={() => history.push(`/admin/job/${record.id}`)} />
                                    <Popconfirm
                                        title='Are you sure you want to delete this job?'
                                        onCancel={(e) => e?.stopPropagation()}
                                        onConfirm={(e) => {
                                            e?.stopPropagation();
                                            deleteItem(record.id);
                                        }}>
                                        <Button size='small' danger icon={<DeleteOutlined />} title='Delete Job' onClick={e => e.stopPropagation()} />
                                    </Popconfirm>
                                </Space>
                        }
                            
                    ]} 
                    dataSource={state.jobs} />
                    <Space>
                        <Button type='primary' icon={<PlusOutlined/>} onClick={() => history.push(`/admin/job/`)} >Add</Button>
                    </Space>

            </div>

        </React.Fragment>
    );
})