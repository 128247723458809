import { Breadcrumb, Button, Col, Form, Input, Row, Select, Space, Upload, InputNumber } from 'antd';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SaveOutlined, ArrowLeftOutlined, UploadOutlined } from '@ant-design/icons';
import { MultiFetcher } from '../../utilities/MultiFetcher';
import { useState } from 'react';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { Manual } from '../../../models/Manual';
import { ParseMlText, StringifyMlText } from '../../utilities/MlTextHelper';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { PageLoader } from '../../utilities/PageLoader';
import { useTranslation } from 'react-i18next';
import { HtmlEditor } from '../_common/HtmlEditor';
import BraftEditor from 'braft-editor';

interface State {
    type: number;
    file?: any; 
}

export const ManualEditor = withAuthenticationRequired(() => { 
    const { id } = useParams<{ id: string }>()
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const history = useHistory();

    const [state, setState] = useState<State>({
        type: 0,
        file: undefined
    });
   
    const {fetchSingle: fetchManual, postSingle: postManual, isLoading: isLoadingManual} = MultiFetcher('manual');   
    const {postFile: uploadDocument, isLoading: isLoadingDocument} = MultiFetcher('document');   

    useEffect(() => {
        window.scrollTo(0, 0)      
    
        if (id)
        {
            const fetchData = async () => {
                const manual = await fetchManual({id, disableCache: true}) as Manual;

                //console.log(`data: ${JSON.stringify(manual)}`);

                let title = ParseMlText(manual.title)
                let description = ParseMlText(manual.description)
                
                form.setFieldsValue({
                    id: manual.id,
                    type: manual.type,
                    order: manual.order,
                    title_en: title['en'],
                    title_nl: title['nl'],
                    title_de: title['de'],
                    description_en: BraftEditor.createEditorState(description['en']),
                    description_nl: BraftEditor.createEditorState(description['nl']),
                    description_de: BraftEditor.createEditorState(description['de']),
                    youTubeCode: manual.youTubeCode,
                    documentId: manual.documentId,
                    documentName: manual.documentName
                });

                setState(prevState => ({ ...prevState, type: manual.type }));
            }

            fetchData();
        } 
        
    }, [fetchManual, form, id]);
  
    const customUploadRequest = (options: UploadRequestOption<any>) => {
        //console.log(`options: ${JSON.stringify(options)}`)
        setState(prevState => ({ ...prevState, file: options.file }));
    }

    const Submit = async (data: Manual) => {
            
        data.title = StringifyMlText(
            form.getFieldValue('title_en'), 
            form.getFieldValue('title_nl'),
            form.getFieldValue('title_de')
        )
            
        data.description = StringifyMlText(
            form.getFieldValue('description_en'), 
            form.getFieldValue('description_nl'),
            form.getFieldValue('description_de')
        )

        //console.log(`data to submit: ${JSON.stringify(data)}`);

        postManual(data).then(async response => {
            if (response) {
                if (state.file && data.type === 0) {
                    const documentId = form.getFieldValue('documentId');
                    const manualId = response.id

                    //console.log(`uploading file: ${JSON.stringify(photo)}`)
                    await uploadDocument(state.file, { 'manualId': manualId }, documentId).then(() => history.push('/admin/Manuals'));
                }
                else {
                    history.push('/admin/Manuals');
                }
            }
        });
    }

    return (
        <React.Fragment>
           
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item>Admin</Breadcrumb.Item>
                <Breadcrumb.Item>Manual</Breadcrumb.Item>
                <Breadcrumb.Item>{form.getFieldValue('title_en')}</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ width: '80%', margin: 'auto', marginBottom: '25px'}}>

                <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px'}} className={'ant-card-head-title'}>Manual Details</div>

                <PageLoader loading={isLoadingManual || isLoadingDocument} subTitle={'Loading data...'}>
                    <Form
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 14 }}
                        layout="horizontal"
                        form={form}
                        onFinish={Submit}
                    >
                        <Form.Item name='id' hidden={true}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='type' label="Type" rules={[{ required: true, message: 'Please select a type!' }]}> 
                            <Select onChange={(value: any) => setState(prevState => ({ ...prevState, type: value })) } style={{maxWidth: 200}}>
                                <Select.Option value={0}>Pdf</Select.Option>
                                <Select.Option value={1}>YouTube</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item name='order' label="Order" rules={[{ required: true, message: 'Please enter an Order!' }]}>
                            <InputNumber min={0} max={100} />
                        </Form.Item>
                        
                        <Form.Item name='title_en' label="Title EN" rules={[{ required: true, max: 100, message: 'Please enter a title! (max. 100 characters)' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='title_nl' label="Title NL" rules={[{ required: true, max: 100, message: 'Please enter a title! (max. 100 characters)' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='title_de' label="Title DE" rules={[{ required: true, max: 100, message: 'Please enter a title! (max. 100 characters)' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='description_en' label="Description EN" rules={[
                            {
                                validateTrigger: 'onBlur',
                                min: 0,
                                max: 1000,
                                message: 'Please enter a Description EN (not more than 1000 characters!)'
                            }
                            ]} >
                            <HtmlEditor height={150} onChange={(editorState) => { form.setFieldsValue({description_en: editorState.toHTML()}) }}/>
                        </Form.Item>

                        <Form.Item name='description_nl' label="Description NL" rules={[
                            {
                                validateTrigger: 'onBlur',
                                min: 0,
                                max: 1000,
                                message: 'Please enter a Description NL (not more than 1000 characters!)'
                            }
                            ]} >
                            <HtmlEditor height={150} onChange={(editorState) => { form.setFieldsValue({description_nl: editorState.toHTML()}) }}/>
                        </Form.Item>

                        <Form.Item name='description_de' label="Description DE" rules={[
                            {
                                validateTrigger: 'onBlur',
                                min: 0,
                                max: 1000,
                                message: 'Please enter a Description DE (not more than 1000 characters!)'
                            }
                            ]} >
                            <HtmlEditor height={150} onChange={(editorState) => { form.setFieldsValue({description_de: editorState.toHTML()}) }}/>
                        </Form.Item>

                        {state.type === 1 ?
                            <Form.Item name='youTubeCode' label="YouTube Code" rules={[{ required: true, pattern: new RegExp('^[A-Za-z0-9]+$'), max: 100, message: 'Please enter a valid YouTube video code! (e.g. GIJK1RjUHRY)' }]}>
                                <Input />
                            </Form.Item> :
                            <Form.Item name='document' label='Document'>
                                <Input value={state.file?.name ?? form.getFieldValue('documentName')} style={{ width: 'calc(100% - 104px)', marginRight: 5 }} readOnly />
                                <Upload 
                                    customRequest={customUploadRequest}
                                    showUploadList={false} 
                                    accept='application/pdf' >
                                    <Button icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </Form.Item>
                        }
                                
                    </Form>

                    <Row>
                        <Col span={14} offset={6} style={{ textAlign: 'right'}}>
                            <Space>
                                <Button type='default' icon={<ArrowLeftOutlined />} onClick={() => history.push('/admin/Manuals')}>{t('common:back')}</Button>
                                <Button type='primary' icon={<SaveOutlined/>} onClick={() => form.submit()}>Save</Button>
                            </Space>
                        </Col>
                    </Row>
                </PageLoader>
                    
            </div>

        </React.Fragment>
    );
})