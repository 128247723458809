import { Button, Card } from 'antd';
import React from 'react';
import i18n from '../../i18n';
import { Manual, ManualType } from '../../models/Manual';
import { ParseMlText } from './MlTextHelper';
import { FilePdfOutlined, YoutubeOutlined, DownloadOutlined } from '@ant-design/icons';

interface Props {
    manual: Manual
}

export const ManualItem = (props: Props) => {

    const language = i18n.language

    const renderIcon = () => {
        switch(props.manual.type) {
            case ManualType.Pdf: return <FilePdfOutlined style={{ marginRight: '20px' }} title='Pdf'/>;
            case ManualType.Youtube: return <YoutubeOutlined style={{ marginRight: '20px' }} title='Youtube'/>;
        };
    }

    const openDocument = () => {
        const link = document.createElement('a');
        link.href = `${process.env.REACT_APP_SERVER_URL}/document/${props.manual.documentId}`;
        link.target = '_blank';
        link.click();
    }

    const renderSubComponent = () => {
        switch(props.manual.type) {
            case ManualType.Pdf: return (
                                    <Button 
                                        type="primary" 
                                        icon={<DownloadOutlined />} 
                                        size={'large'}
                                        onClick={() => openDocument() }>Download</Button>
                                    )
            case ManualType.Youtube: return <iframe 
                                title='Youtube Video'
                                width="75%" 
                                height="380"
                                style={{ maxWidth: 600 }}
                                src={`https://youtube.com/embed/${props.manual.youTubeCode}`}
                                frameBorder={0}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen={true}
                                className='orange-border'>
                            </iframe>
            default: return <div></div>
        }
    }

    return(
        <div className='content-flex'>
            <Card
                bordered={false}
                className='card card-single'
                headStyle={{ borderBottom: '0px' }}
                bodyStyle={{ paddingTop: 0}}
                title={
                    <span>{renderIcon()}{ParseMlText(props.manual.title)[language]}</span>
                }>
                <div dangerouslySetInnerHTML={{__html: ParseMlText(props.manual.description)[language] }} />
                <br/>
                { renderSubComponent() }
                <br/>
            </Card>
        </div>
    )
}