import { Breadcrumb } from 'antd';
import React, { useEffect } from 'react';
import 'react-alice-carousel/lib/alice-carousel.css'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
// import { useParams } from 'react-router-dom';
import { CardSingle } from './utilities/CardSingle';
import { CarouselViewer } from './utilities/Carousel';

export const LowEntry = () => { 

    const { t } = useTranslation();
    // let { lng } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    return (
      <React.Fragment>
        <Helmet>
            <title>InnoTrans | {t('common:lowentry')}</title>
            <meta name="description" content={t('pageDescriptions:lowentry')} />
            <meta name="keywords" content={t('pageKeywords:lowentry')} />
            {/* <link rel="canonical" href={`https://innotrans.nl${lng ? `/${lng}` : ''}/lowentry`} /> */}
        </Helmet>
        
         <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
            <Breadcrumb.Item><h1>{t('common:lowentry')}</h1></Breadcrumb.Item>
        </Breadcrumb>

        <CarouselViewer id='642989f5-dccf-4925-a103-ca35a40f40aa' />

        <CardSingle title={t('lowentry:card1-title')}>
            <div dangerouslySetInnerHTML={{__html: t('lowentry:card1-text') }} />
        </CardSingle>

        <CardSingle title={t('lowentry:card2-title')}>
          <div className='bullet-list'>
              <div className='bullet-item'>
                  <img src={'/images/shield.png'} alt='' className='bullet-image'/> {t('lowentry:card2-bullet-1')}<br/>
              </div>
              <div className='bullet-item'>
                  <img src={'/images/shield.png'} alt='' className='bullet-image'/> {t('lowentry:card2-bullet-2')}<br/>
              </div>
              <div className='bullet-item'>
                  <img src={'/images/shield.png'} alt='' className='bullet-image'/> {t('lowentry:card2-bullet-3')}<br/>
              </div>
              <div className='bullet-item'>
                  <img src={'/images/shield.png'} alt='' className='bullet-image'/> {t('lowentry:card2-bullet-4')}<br/>
              </div>
              <div className='bullet-item'>
                  <img src={'/images/shield.png'} alt='' className='bullet-image'/> {t('lowentry:card2-bullet-5')}<br/>
              </div>
              <div className='bullet-item'>
                  <img src={'/images/shield.png'} alt='' className='bullet-image'/> {t('lowentry:card2-bullet-6')}<br/>
              </div>
              <div className='bullet-item'>
                  <img src={'/images/shield.png'} alt='' className='bullet-image'/> {t('lowentry:card2-bullet-7')}<br/>
              </div>
          </div>
        </CardSingle>

      </React.Fragment>
    )
}