import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Spin } from "antd";
import React from "react";
import { Route, RouteProps } from "react-router-dom";

const ProtectedRoute = (props: RouteProps) => (
    <Route {...props} />
);

export default withAuthenticationRequired(ProtectedRoute, {
    onRedirecting: () => <Spin spinning={true} />
  });