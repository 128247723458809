import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Breadcrumb, Descriptions } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { PageLoader } from '../utilities/PageLoader';

interface State {
  scopes: string[];
}

export const UserInfo = withAuthenticationRequired(() => {

    const { user, isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();

    const [state, setState] = useState<State>({
        scopes: [],
    });

    const getScopesFromToken = useCallback(async () => {
        const getUserScopesFromToken = (token:string) => {
          if (token) {
            try {
              const user = JSON.parse(atob(token.split('.')[1]));
              return user.scope.split(' ');
            } catch (error) { }
          }
        
          return null;
        };
    
        const token = await getAccessTokenSilently();
        const scopes = getUserScopesFromToken(token);
        setState(prevState => ({ ...prevState, token,  scopes }));
    
        //console.log(`token: ${JSON.stringify(token)}`);
        //console.log(`scopes: ${JSON.stringify(scopes)}`);
      }, [getAccessTokenSilently]);
      
    useEffect(() => {
        if (isAuthenticated) { 
            getScopesFromToken(); 
        }
    }, [getScopesFromToken, isAuthenticated]);

    return (
        <React.Fragment>
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item>Admin</Breadcrumb.Item>
                <Breadcrumb.Item>UserInfo</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ width: '80%', margin: 'auto', marginBottom: '25px'}}>

                <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px'}} className={'ant-card-head-title'}>User Info</div>

                <PageLoader loading={isLoading} subTitle={'Loading ...'}>
                    <Descriptions
                        bordered
                        size='middle' >
                        <Descriptions.Item label='Avatar' span={3}><img src={user?.picture} alt={user?.name} style={{ width: '50px' }} /></Descriptions.Item>
                        <Descriptions.Item label="Name" span={3}>{user?.name}</Descriptions.Item>
                        <Descriptions.Item label="Nickname" span={3}>{user?.nickname}</Descriptions.Item>
                        <Descriptions.Item label="Email" span={3}>{user?.email}</Descriptions.Item>
                        <Descriptions.Item label="Email&nbsp;Verified" span={3}>{user?.email_verified?.toString()}</Descriptions.Item>
                        <Descriptions.Item label="Updated" span={3}>{user?.updated_at}</Descriptions.Item>
                        <Descriptions.Item label="Scopes" span={3}>{state.scopes.join(' ')}</Descriptions.Item>
                        {/* <Descriptions.Item label="User" span={3}>{JSON.stringify(user)}</Descriptions.Item> */}
                    </Descriptions>
                </PageLoader>

            </div>

        </React.Fragment>
    )
})