import React, { useCallback, useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel'
import { Image } from 'antd';
import { PageLoader } from './PageLoader';
import { MultiFetcher } from './MultiFetcher';
import { Carousel } from '../../models/Carousel';
import * as _ from 'lodash';

interface Props {
    id: string
}

interface State {
    imageIds: string[]
}

export const CarouselViewer = (props: Props) => {

    const [state, setState] = useState<State>({
        imageIds: []
      });

    const {fetchSingle: fetchCarousel, isLoading} = MultiFetcher('carousel');   

    const fetchData = useCallback(async () => {
        const carousel = await fetchCarousel({id: props.id}) as Carousel;
        setState(prevState => ({ ...prevState, imageIds: carousel.imageIds }));
    }, [fetchCarousel, props.id]);

    useEffect(() => {
        window.scrollTo(0, 0)

        fetchData();
    }, [fetchData])


    return (
        <PageLoader loading={isLoading} subTitle={'Loading data...'}>
            <div className='content-flex'>
                <AliceCarousel 
                    autoPlay={true}
                    duration={3000}
                    responsive={{
                        0: { items: 1 },
                        600: { items: 2},
                        1200: { items: 3 },
                        1800: { items: 4 }
                    }}
                    buttonsDisabled={true}
                    dotsDisabled={true}
                    mouseTrackingEnabled={false}
                    autoHeight={false}
                    stagePadding={{
                        paddingLeft: 0,
                        paddingRight: 0
                    }}>
                        { _.map(state.imageIds, (imageId, index) => {
                            return <Image key={index} src={`${process.env.REACT_APP_SERVER_URL}/image/${imageId}`} style={{ maxHeight: 400, minHeight: 200, cursor: 'pointer' }} loading='lazy' />
                        })}
                </AliceCarousel>
            </div>
        </PageLoader>
    )
}