import { Card, Row, Col, Image, Button, Breadcrumb } from 'antd';
import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Meta from 'antd/lib/card/Meta';
import { PushpinOutlined, PhoneOutlined, MailOutlined, PrinterOutlined } from '@ant-design/icons';
import 'react-alice-carousel/lib/alice-carousel.css'
import { CardSingle } from './utilities/CardSingle';
import { CardImageLeft } from './utilities/CardImageLeft';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import AliceCarousel from 'react-alice-carousel';
import { CarouselViewer } from './utilities/Carousel';
import i18n from '../i18n';

export const Home = () => { 
    const history = useHistory();
    const { t } = useTranslation();
    // let { lng } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
      <React.Fragment>
        <Helmet>
            <title>InnoTrans | {t('common:home')}</title>
            <meta name="description" content={t('pageDescriptions:home')} />
            <meta name="keywords" content={t('pageKeywords:home')} />
            <meta httpEquiv="content-language" content={i18n.language?.substr(0,2)} />
            {/* <link rel="canonical" href={`https://innotrans.nl${lng ? `/${lng}` : ''}`} /> */}
        </Helmet>

        <Breadcrumb className='breadcrumb'>
            <Breadcrumb.Item><h1>{t('common:home')}</h1></Breadcrumb.Item>
        </Breadcrumb>

        <CarouselViewer id='5c3217c8-4eb2-4732-bf05-a74ba75e8966' />

        <CardSingle title={t('home:card1-title')}>
            <div dangerouslySetInnerHTML={{__html: t('home:card1-text') }} />
        </CardSingle>

        <div className='content-flex bullet-bar' >
            <div style={{ width: '300px', textAlign: 'center' }}>{t('home:bullet-item-1')}</div>
            <div style={{ width: '300px', textAlign: 'center' }}>{t('home:bullet-item-2')}</div>
            <div style={{ width: '300px', textAlign: 'center' }}>{t('home:bullet-item-3')}</div>
        </div>

        <br/>

        <CardSingle title={t('home:card2-title')}>
            <div dangerouslySetInnerHTML={{__html: t('home:card2-text') }} />
        </CardSingle>
         
        <CardImageLeft image='/images/home/floorsystem.jpg'>
            <div className='bullet-list'>
                <div className='bullet-item'>
                    <img src={'/images/shield.png'} alt='Floorsystem bulletpoint 1' className='bullet-image'/> {t('home:card3-bullet-1')}<br/>
                </div>
                <div className='bullet-item'>
                    <img src={'/images/shield.png'} alt='Floorsystem bulletpoint 2' className='bullet-image'/> {t('home:card3-bullet-2')}<br/>
                </div>
                <div className='bullet-item'>
                    <img src={'/images/shield.png'} alt='Floorsystem bulletpoint 3' className='bullet-image'/> {t('home:card3-bullet-3')}<br/>
                </div>
                <div className='bullet-item'>
                    <img src={'/images/shield.png'} alt='Floorsystem bulletpoint 4' className='bullet-image'/> {t('home:card3-bullet-4')}<br/>
                </div>
                <div className='bullet-item'>
                    <img src={'/images/shield.png'} alt='Floorsystem bulletpoint 5' className='bullet-image'/> {t('home:card3-bullet-5')}<br/>
                </div>
                <div className='bullet-item'>
                    <img src={'/images/shield.png'} alt='Floorsystem bulletpoint 6' className='bullet-image'/> {t('home:card3-bullet-6')}<br/>
                </div>
                <div className='bullet-item'>
                    <img src={'/images/shield.png'} alt='Floorsystem bulletpoint 7' className='bullet-image'/> {t('home:card3-bullet-7')}<br/>
                </div>
                <div className='bullet-item'>
                    <img src={'/images/shield.png'} alt='Floorsystem bulletpoint 8' className='bullet-image'/> {t('home:card3-bullet-8')}<br/>
                </div>
                <div className='bullet-item'>
                    <img src={'/images/shield.png'} alt='Floorsystem bulletpoint 9' className='bullet-image'/> {t('home:card3-bullet-9')}<br/>
                </div>
                <div className='bullet-item'>
                    <img src={'/images/shield.png'} alt='Floorsystem bulletpoint 10' className='bullet-image'/> {t('home:card3-bullet-10')}<br/>
                </div>
            </div>
        </CardImageLeft>

        <br/>
        
        <br/>
       
        <div className='content-flex bg-blue-padded' >
            <Card
                hoverable
                className='card-width-400-padded'          
                cover={<Image src='/images/home/wheelchairbus.jpg' alt='Wheelchairbus' preview={false} fallback='/images/default.png' loading='lazy' />}
                onClick={ () => history.push("/wheelchairbus") }>
                <Meta title={t('common:wheelchairbus')} />
            </Card>
            <Card
                hoverable
                className='card-width-400-padded'
                cover={<Image src='/images/home/wheelchaircar.jpg' alt='Wheelchaircar' preview={false} fallback='/images/default.png' loading='lazy' />}
                onClick={ () => history.push("/wheelchaircar") }>
                <Meta title={t('common:wheelchaircar')} />
            </Card>
            <Card
                hoverable
                className='card-width-400-padded'
                cover={<Image src='/images/home/lowentry.jpg' alt='Lowentry' preview={false} fallback='/images/default.png' loading='lazy' />}
                onClick={ () => history.push("/lowentry") }>
                <Meta title={t('common:lowentry')} />
            </Card>
        </div>

        <br/>

        <div className='content-flex'>
            <AliceCarousel 
                autoPlay={true}
                duration={3000}
                responsive={{
                    100: { items: 1 },
                    180: { items: 2 },
                    260: { items: 3 },
                    340: { items: 4 },
                    420: { items: 5 },
                    500: { items: 6 },
                    580: { items: 7 }
                }}
                buttonsDisabled={true}
                dotsDisabled={true}
                mouseTrackingEnabled={false}
                autoHeight={false}
                stagePadding={{
                    paddingLeft: 0,
                    paddingRight: 0
                }}>
                <Image src="/images/brands/mercedes.png" preview={false} style={{ maxWidth: '80px' }} fallback='/images/default.png' loading='lazy' alt='Mercedes' />
                <Image src="/images/brands/vw.jpg" preview={false} style={{ maxWidth: '80px' }} fallback='/images/default.png' loading='lazy' alt='Volkswagen' />
                <Image src="/images/brands/man.png" preview={false} style={{ maxWidth: '80px' }} fallback='/images/default.png' loading='lazy' alt='MAN' />
                <Image src="/images/brands/fiat.png" preview={false} style={{ maxWidth: '80px' }} fallback='/images/default.png' loading='lazy' alt='Fiat' />
                <Image src="/images/brands/citroen.png" preview={false} style={{ maxWidth: '80px' }} fallback='/images/default.png' loading='lazy' alt='Citroen' />
                <Image src="/images/brands/peugeot.png" preview={false} style={{ maxWidth: '80px' }} fallback='/images/default.png' loading='lazy' alt='Peugeot'/>
                <Image src="/images/brands/rdw.png" preview={false} style={{ maxWidth: '80px' }} fallback='/images/default.png' loading='lazy' alt='RDW' />
                <Image src="/images/brands/tuv-iso9001.png" preview={false} style={{ maxWidth: '80px' }} fallback='/images/default.png' loading='lazy' alt='TUV ISO-9001' />
            </AliceCarousel>
        </div>

        <br/>
        <hr />

        <div className='content-flex' style={{ paddingTop: '25px' }} >
            <Image src={'/images/logo_large.jpg'} preview={false} alt='Innotrans'/>
        </div>
        <div className='content-flex' style={{ paddingTop: '10px', paddingBottom: '25px' }} >
            <div className={'ant-card-meta-description'} style={{ textAlign: 'left', lineHeight: 2, minWidth: '300px'}}>
                 <Row>
                    <Col span={3} offset={5}><PushpinOutlined /></Col>
                    <Col span={15}>Hazeldonk 6267</Col>
                </Row>
                <Row>
                    <Col span={3} offset={5}></Col>
                    <Col span={15}>4836 LG Breda</Col>
                </Row>
                <Row></Row>
                <Row>
                    <Col span={3} offset={5}><PhoneOutlined /></Col>
                    <Col span={15}>+31 (0)76 596 9740</Col>
                </Row>
                <Row>
                    <Col span={3} offset={5}><PrinterOutlined /></Col>
                    <Col span={15}>+31 (0)76 596 9741</Col>
                </Row>
                <Row>
                    <Col span={3} offset={5}><MailOutlined /></Col>
                    <Col span={15}><a href='mailto:info@innotrans.nl'>info@innotrans.nl</a></Col>
                </Row>
                <Row style={{ paddingTop: '25px'}}>
                    <Col span={3} offset={2}>
                        <Button 
                            type="default" 
                            shape="circle" 
                            icon={<Image src='/images/home/social/linkedin.png' style={{ maxWidth: '38px'}} preview={false} fallback='/images/default.png' loading='lazy' alt='LinkedIn' />} 
                            style={{ padding: 0 }}
                            size='large' 
                            title='LinkedIn'
                            onClick={ () => window.location.assign('https://www.linkedin.com/in/innotrans-breda-87a3b0140/') }/>
                    </Col>
                    <Col span={3} offset={5}>
                        <Button 
                            type="default" 
                            shape="circle" 
                            icon={<Image src='/images/home/social/facebook.png' style={{ maxWidth: '38px' }} preview={false} fallback='/images/default.png' loading='lazy' alt='Facebook' />} 
                            style={{ padding: 0 }}
                            size='large' 
                            title='Facebook'
                            onClick={ () => window.location.assign('http://www.facebook.com') }/>
                    </Col>
                    <Col span={3} offset={5}>
                        <Button 
                            type="default" 
                            shape="circle" 
                            icon={<Image src='/images/home/social/instagram.png' style={{ maxWidth: '38px' }} preview={false} fallback='/images/default.png' loading='lazy' alt='Instagram' />} 
                            style={{ padding: 0 }}
                            size='large' 
                            title='Instagram' 
                            onClick={ () => window.location.assign('https://www.instagram.com/innotrans_breda/') }/>
                    </Col>
                </Row>
                    
            </div>
        </div>

      </React.Fragment >
    );
  };