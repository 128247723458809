import React from 'react';
import { Card, Col, Row } from 'antd';

interface Props {
    title1?: string;
    title1Disabled?: boolean;
    children1?: React.ReactNode;
    style1?: React.CSSProperties;
    title2?: string;
    title2Enabled?: boolean;
    children2?: React.ReactNode;
    style2?: React.CSSProperties;
}

export const CardDouble = (props: Props) => {

    return (
        <React.Fragment>
            <Row>
                <Col 
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 10, offset: 2 }}
                    xl={{ span: 9, offset: 3 }}>
                    <Card
                        bordered={false}
                        className='card card-double'
                        title={props.title1 ?? 'HIDDEN'}
                        headStyle={!(props.title1Disabled === true) ?
                            (props.title1 ? {} : { visibility: 'hidden' })
                            : { display: 'none' }
                        }
                        style={props.style1}>
                            {props.children1}
                    </Card>
                </Col>
                <Col 
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 10 }}
                    xl={{ span: 9 }}>
                    <Card
                        bordered={false}
                        className='card card-double'
                        title={props.title2 ?? 'HIDDEN'}
                        headStyle={!(props.title2Enabled === true) ?
                            (props.title2 ? {} : { visibility: 'hidden' })
                            : { display: 'none' }
                        }
                        style={props.style2}>
                            {props.children2}
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}