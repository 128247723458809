export class NewsItem {
    public id: string
    public date: Date
    public type: number
    public title: string
    public description: string
    public youTubeCode: string
    public imageIds: string[]
}

export enum NewsItemType {
    Photo = 0,
    Youtube = 1
  }
