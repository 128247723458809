import { Breadcrumb, Button, Checkbox, Col, Form, Input, InputNumber, Row, Space } from 'antd';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SaveOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { MultiFetcher } from '../../utilities/MultiFetcher';
import { useState } from 'react';
import * as _ from 'lodash';
import { Image } from '../../../models/Image';
import { Part } from '../../../models/Part';
import { ParseMlText, StringifyMlText } from '../../utilities/MlTextHelper';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { PageLoader } from '../../utilities/PageLoader';
import { ImagesManager } from '../_common/ImagesManager';
import { PartCategory } from '../../../models/PartCategory';
import { PartCategoriesDropDown } from '../_common/PartCategoriesDropDown';
import { useTranslation } from 'react-i18next';
import { HtmlEditor } from '../_common/HtmlEditor';
import BraftEditor from 'braft-editor';

interface State {
    images: Image[]
    categories: PartCategory[]
}

export const PartEditor = withAuthenticationRequired(() => { 
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const [ form ] = Form.useForm();
    //const getFieldDecorator = Form.useForm()
    const history = useHistory();

    const [state, setState] = useState<State>({
        images: [],
        categories: []
    });
   
    const {fetchSingle: fetchPart, postSingle: postPart, isLoading: isLoadingPart} = MultiFetcher('part');   
    const {fetchMultiple: fetchCategories, isLoading: isLoadingCategories} = MultiFetcher('partCategory');   
    const {postFile: uploadImage, isLoading: isLoadingImage} = MultiFetcher('image');   

    useEffect(() => {
        window.scrollTo(0, 0)      
    
        if (id)
        {
            const fetchData = async () => {
                const part = await fetchPart({id, disableCache: true}) as Part;

                //console.log(`data: ${JSON.stringify(Part)}`);
                let name = ParseMlText(part.name)
                let description = ParseMlText(part.description)
                
                form.setFieldsValue({
                    id: part.id,
                    categoryId: part.categoryId,
                    name_en: name['en'],
                    name_nl: name['nl'],
                    name_de: name['de'],
                    description_en: BraftEditor.createEditorState(description['en']),
                    description_nl: BraftEditor.createEditorState(description['nl']),
                    description_de: BraftEditor.createEditorState(description['de']),
                    price: part.price,
                    visible: part.visible
                });

                //console.log(`visible: ${part.visible}`)
                //console.log(`imageIds: ${Part.imageIds}`)

                let images : Image[] = []

                _.map(part.imageIds, imageId => {
                    images.push({
                        imageId: imageId
                    } as Image)
                })

                //console.log(`images: ${JSON.stringify(images)}`)

                setState(prevState => ({ ...prevState, images }));
            }

            fetchData();
        }
        else
        {
            form.setFieldsValue({ visible: true });
        }
        
        const fetchAllCategories = async() => {
            const allCategories = await fetchCategories({disableCache: true}) as PartCategory[];
            const categories = _.sortBy(allCategories.filter(cat => allCategories.find(subcat => subcat.parentId === cat.id) == null), 'parentName', 'name');
            setState(prevState => ({ ...prevState, categories }));
            //console.log(`categories: ${JSON.stringify(categories)}`)
        }

        fetchAllCategories();
        
    }, [fetchCategories, fetchPart, form, id]);
  
    const onImagesChanged = ((images: Image[]) => {
        setState(prevState => ({ ...prevState, images }));
    })

    const submitPart = async (data: Part) => {
        data.name = StringifyMlText(
            form.getFieldValue('name_en'), 
            form.getFieldValue('name_nl'),
            form.getFieldValue('name_de')
        )
            
        data.description = StringifyMlText(
            form.getFieldValue('description_en'), 
            form.getFieldValue('description_nl'),
            form.getFieldValue('description_de')
        )

        //console.log(`data to submit: ${JSON.stringify(data)}`);

        return await postPart(data)
    }

    const Submit = async (data: Part) => {
        const images = state.images;

        Promise.all(_.map(images.filter(image => image.imageId == null), async (image) => {
            image.imageId = await uploadImage(image.file)
        })).then(() => {
            data.imageIds = images.map(image => image.imageId) as string[]

            //console.log(`data.imageIds: ${JSON.stringify(data.imageIds)}`)

            submitPart(data).then(() => {
                history.push('/Admin/Parts') 
            });
        })
    }

    return (
        <React.Fragment>
           
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item>Admin</Breadcrumb.Item>
                <Breadcrumb.Item>Parts</Breadcrumb.Item>
                <Breadcrumb.Item>{form.getFieldValue('name_en')}</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ width: '90%', margin: 'auto', marginBottom: '25px'}}>

                <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px'}} className={'ant-card-head-title'}>Part Details</div>

                <PageLoader loading={isLoadingPart || isLoadingImage} subTitle={'Loading data...'}>
                    <Form
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 14 }}
                        layout="horizontal"
                        form={form}
                        onFinish={Submit}
                    >
                        <Form.Item name='id' hidden={true}>
                            <Input />
                        </Form.Item>

                        <PartCategoriesDropDown categories={state.categories} isLoading={isLoadingCategories} required={true}/>
                        
                        <Form.Item name='name_en' label="Name EN" rules={[{ required: true, max: 100, message: 'Please enter a name! (max. 100 characters)' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='name_nl' label="Name NL" rules={[{ required: true, max: 100, message: 'Please enter a name! (max. 100 characters)' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='name_de' label="Name DE" rules={[{ required: true, max: 100, message: 'Please enter a name! (max. 100 characters)' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='description_en' label="Description EN" rules={[
                            {
                                required: true,
                                validateTrigger: 'onBlur',
                                min: 1,
                                max: 1000,
                                message: 'Please enter a Description EN (between 1 and 1000 characters!)'
                            }
                            ]} >
                            <HtmlEditor height={150} onChange={(editorState) => { form.setFieldsValue({description_en: editorState.toHTML()}) }}/>
                        </Form.Item>

                        <Form.Item name='description_nl' label="Description NL" rules={[
                            {
                                required: true,
                                validateTrigger: 'onBlur',
                                min: 1,
                                max: 1000,
                                message: 'Please enter a Description NL (between 1 and 1000 characters!)'
                            }
                            ]} >
                            <HtmlEditor height={150} onChange={(editorState) => { form.setFieldsValue({description_nl: editorState.toHTML()}) }}/>
                        </Form.Item>

                        <Form.Item name='description_de' label="Description DE" rules={[
                            {
                                required: true,
                                validateTrigger: 'onBlur',
                                min: 1,
                                max: 1000,
                                message: 'Please enter a Description DE (between 1 and 1000 characters!)'
                            }
                            ]} >
                            <HtmlEditor height={150} onChange={(editorState) => { form.setFieldsValue({description_de: editorState.toHTML()}) }}/>
                        </Form.Item>
                        <Form.Item name='price' label="Price" > 
                            <InputNumber min={0} max={9999999.99} precision={2} maxLength={9} style={{ width: 200 }} 
                                //formatter={value => value ? (value as string).replace(',', '').replace('.', ',') : ''}
                                //formatter={(value) => `€ ${Intl.NumberFormat('nl-NL').format(value as number)}`}
                                // parser={value => `€ ${Intl.NumberFormat('nl-NL').format(value)}`}
                                />
                        </Form.Item>

                        <Form.Item name='visible' label="Visible"  valuePropName="checked">
                            <Checkbox />
                        </Form.Item>

                        <Form.Item name='images' label="Images">
                            <ImagesManager images={state.images} max={3} onChange={onImagesChanged} />
                        </Form.Item>
                                
                    </Form>

                    <Row>
                        <Col span={14} offset={6} style={{ textAlign: 'right'}}>
                            <Space>
                                <Button type='default' icon={<ArrowLeftOutlined />} onClick={() => history.push('/Admin/Parts')}>{t('common:back')}</Button>
                                <Button type='primary' icon={<SaveOutlined/>} onClick={() => form.submit()}>Save</Button>
                            </Space>
                        </Col>
                    </Row>
                </PageLoader>
                    
            </div>

        </React.Fragment>
    );
})