import { Menu } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SettingOutlined, ToolOutlined, PictureOutlined, ReadOutlined, PartitionOutlined, ProfileOutlined, CarOutlined, UsergroupAddOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useAuth0 } from "@auth0/auth0-react";
import { PageLoader } from '../utilities/PageLoader';

interface State {
    selectedKeys: []
    scopes: string[]
  }

export const AdminMenu = () => {

    const [state, setState] = useState<State>({
        selectedKeys: [],
        scopes: []
      });
    
    const { isAuthenticated, getAccessTokenSilently, isLoading, logout } = useAuth0();

    const getScopesFromToken = useCallback(async () => {
        const getUserScopesFromToken = (token:string) => {
          if (token) {
            try {
              const user = JSON.parse(atob(token.split('.')[1]));
              return user.scope.split(' ');
            } catch (error) { }
          }
        
          return null;
        };
    
        const token = await getAccessTokenSilently();
        const scopes = getUserScopesFromToken(token);
        setState(prevState => ({ ...prevState, token,  scopes }));
    
        //console.log(`token: ${JSON.stringify(token)}`);
        //console.log(`scopes: ${JSON.stringify(scopes)}`);
      }, [getAccessTokenSilently]);
      
    useEffect(() => {
        if (isAuthenticated) { 
            getScopesFromToken(); 
        }
    }, [getScopesFromToken, isAuthenticated]);

    const hasScope = (scope: string) : boolean => {
        if (state.scopes)
        {
          return state.scopes.includes(scope);
        }
        else
        {
          return false;
        }
      }

    return (
        <React.Fragment>
            <PageLoader loading={isLoading} subTitle={'Loading ...'}>
                <Menu 
                    theme="light" 
                    mode="horizontal" 
                    overflowedIndicator={<SettingOutlined style={{ fontSize: 18, margin: 'auto'}} value={0} />} 
                    style={{ color: '#378bcf', verticalAlign: 'text-top', marginTop: -8, width: 20, textAlign: 'right' }} 
                    selectedKeys={state.selectedKeys}
                    triggerSubMenuAction={'hover'}>
                    { hasScope('manage:newsitems') 
                        ?
                        <Menu.Item key="1" icon={<ReadOutlined />} onClick={() => setState(prevState => ({ ...prevState, selectedKeys: [] }))}>
                            <Link to='/Admin/Newsitems'><span>News Items</span></Link>
                        </Menu.Item>
                        : null }
                    { hasScope('manage:manuals') 
                        ?
                        <Menu.Item key="2" icon={<ProfileOutlined />} onClick={() => setState(prevState => ({ ...prevState, selectedKeys: [] }))}>
                            <Link to='/Admin/Manuals'><span>Manuals</span></Link>
                        </Menu.Item>
                        : null }

                    { hasScope('manage:carousels') 
                        ?
                        <Menu.Item key="3" icon={<PictureOutlined />} onClick={() => setState(prevState => ({ ...prevState, selectedKeys: [] }))}>
                            <Link to='/Admin/Carousels'><span>Carousels</span></Link>
                        </Menu.Item>
                        : null }

                    { hasScope('manage:parts') 
                        ?
                        <React.Fragment>
                          <Menu.Item key="4" icon={<PartitionOutlined />} onClick={() => setState(prevState => ({ ...prevState, selectedKeys: [] }))}>
                              <Link to='/Admin/PartCategories'><span>Part Categories</span></Link>
                          </Menu.Item>
                          <Menu.Item key="5" icon={<ToolOutlined />} onClick={() => setState(prevState => ({ ...prevState, selectedKeys: [] }))}>
                            <Link to='/Admin/Parts'><span>Parts</span></Link>
                          </Menu.Item>
                        </React.Fragment>
                        : null }

                    { hasScope('manage:stocks') 
                        ?
                        <React.Fragment>
                          <Menu.Item key="6" icon={<PartitionOutlined />} onClick={() => setState(prevState => ({ ...prevState, selectedKeys: [] }))}>
                              <Link to='/Admin/StockCategories'><span>Stock Categories</span></Link>
                          </Menu.Item>
                          <Menu.Item key="7" icon={<CarOutlined />} onClick={() => setState(prevState => ({ ...prevState, selectedKeys: [] }))}>
                            <Link to='/Admin/Stocks'><span>Stocks</span></Link>
                          </Menu.Item>
                        </React.Fragment>
                        : null }

                    { hasScope('manage:jobs') 
                        ?
                        <React.Fragment>
                          <Menu.Item key="8" icon={<UsergroupAddOutlined />} onClick={() => setState(prevState => ({ ...prevState, selectedKeys: [] }))}>
                            <Link to='/Admin/Jobs'><span>Jobs</span></Link>
                          </Menu.Item>
                        </React.Fragment>
                        : null }


                    <Menu.Divider />

                    <Menu.Item key="10" icon={<UserOutlined />} onClick={() => setState(prevState => ({ ...prevState, selectedKeys: [] }))}>
                        <Link to='/Admin/UserInfo'><span>User Info</span></Link>
                    </Menu.Item>

                    <Menu.Item key="11" icon={<LogoutOutlined />} onClick={() => {
                        setState(prevState => ({ ...prevState, selectedKeys: [] }))
                        logout({ returnTo: window.location.origin })
                      }}>
                        <Link to='#'><span>Log Out</span></Link>
                    </Menu.Item>
                </Menu>
            </PageLoader>
        </React.Fragment>
    )
}