import React, { useEffect } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { Layout, Menu, Row, Col, Affix, Image, BackTop, Button } from 'antd';
import { MenuOutlined,  ArrowUpOutlined } from '@ant-design/icons';
import "antd/dist/antd.css";
import './App.css';
import i18n from './i18n';
import { useTranslation } from 'react-i18next';
import { LanguagePicker } from './Components/utilities/LanguagePicker';
import { Home } from './Components/Home';
import { WheelchairBus } from './Components/WheelchairBus';
import { WheelchairCar } from './Components/WheelchairCar';
import { LowEntry } from './Components/LowEntry';
import { Parts } from './Components/Parts';
import { Manuals } from './Components/Manuals';
import { News } from './Components/News';
import { Contact } from './Components/Contact';
import { Status500 } from './Components/errors/500';
import { Status404 } from './Components/errors/404';
import { Status403 } from './Components/errors/403';
import { NewsItemList } from './Components/Admin/News/NewsItemList';
import { NewsItemEditor } from './Components/Admin/News/NewsItemEditor';
import { Login } from './Components/Admin/Login';
import { useAuth0 } from "@auth0/auth0-react";
import ProtectedRoute from './Components/utilities/ProtectedRoute';
import { AdminMenu } from './Components/Admin/AdminMenu';
import { UserInfo } from './Components/Admin/UserInfo';
import { PageLoader } from './Components/utilities/PageLoader';
import { ManualList } from './Components/Admin/Manuals/ManualList';
import { ManualEditor } from './Components/Admin/Manuals/ManualEditor';
import { CarouselList } from './Components/Admin/Carousels/CarouselList';
import { CarouselEditor } from './Components/Admin/Carousels/CarouselEditor';
import ReactGA from 'react-ga4';
import { PartCategoryList } from './Components/Admin/PartCategories/PartCategoryList';
import { PartCategoryEditor } from './Components/Admin/PartCategories/PartCategoryEditor';
import { PartList } from './Components/Admin/Parts/PartList';
import { PartEditor } from './Components/Admin/Parts/PartEditor';
import { PartDetails } from './Components/PartDetails';
import { StockCategoryList } from './Components/Admin/StockCategories/StockCategoryList';
import { StockCategoryEditor } from './Components/Admin/StockCategories/StockCategoryEditor';
import { StockList } from './Components/Admin/Stocks/StockList';
import { StockEditor } from './Components/Admin/Stocks/StockEditor';
import { Stocks } from './Components/Stocks';
import { StockDetails } from './Components/StockDetails';
import { JobList } from './Components/Admin/Jobs/JobList';
import { JobEditor } from './Components/Admin/Jobs/JobEditor';
import { Jobs } from './Components/Jobs';
import { JobDetails } from './Components/JobDetails';
import { ElectricTransport } from './Components/ElectricTransport';

const { Header, Content, Footer } = Layout;

export const App = () => {

  const { t } = useTranslation();
  const { isAuthenticated, isLoading } = useAuth0();
  
  useEffect(() => {
    console.log(`Language: [${i18n.language}]`)
    if (i18n.language.length > 2) {
      changeLanguage(i18n.language.substr(0,2));
      //console.log(`Changed to Language: [${i18n.language}]`)
    }

    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search })
    //console.log(`reactga.pageview(${window.location.pathname + window.location.search}`)
  }, [])

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    //console.log(`New language: [${i18n.language}]`);
  };

  return (
    <React.Fragment>
      <PageLoader loading={isLoading} subTitle={'Loading ...'}>
        <Layout style={{ minHeight: '100vh' }}>
          <Affix offsetTop={0}>
            <Header style={{ paddingLeft: 0, paddingRight: 0}}>
              <Row style={{ paddingLeft: 15, paddingRight: 15, borderBottom: '1px solid #a1aec5', backgroundColor: 'white' }}>
                <Col 
                  xs={{ span: 18 }}
                  sm={{ span: 11 }}
                  md={{ span: 9 }}
                  lg={{ span: 7 }}
                  xl={{ span: 6 }}>
                  <Link to='/' title={t('pageDescriptions:home')} >
                    <Image src='/images/logo_large.jpg' fallback='/images/default.png' style={{minHeight: 20, maxHeight: 60}} preview={false} loading='lazy' title='Innotrans Logo' />
                  </Link>
                </Col>
                <Col 
                  xs={{ span: 2 }}
                  sm={{ span: 11 }}
                  md={{ span: 13 }}
                  lg={{ span: 14 }}
                  xl={{ span: 16 }}
                  style={{ textAlign: 'right' }}>
                  <Menu 
                    theme="light" 
                    mode="horizontal" 
                    selectedKeys={[]}
                    style={{ borderBottom: 0, fontSize: 17, fontWeight: 500, verticalAlign: 'text-top', marginTop: -10 }}
                    overflowedIndicator={<MenuOutlined style={{ fontSize: '18px', fontWeight: 800, color: '#f87046', marginBottom: '-5px'}} />}>
                    <Menu.Item key="1"><Link to={`/wheelchairbus`} style={{ color: '#f87046'}} title={t('pageDescriptions:wheelchairbus')}><span>{t('common:wheelchairbus')}</span></Link></Menu.Item>
                    <Menu.Item key="2"><Link to={`/wheelchaircar`} style={{ color: '#f87046'}} title={t('pageDescriptions:wheelchaircar')}><span>{t('common:wheelchaircar')}</span></Link></Menu.Item>
                    <Menu.Item key="3"><Link to={`/electrictransport`} style={{ color: '#f87046'}} title={t('pageDescriptions:electrictransport')}><span>{t('common:electrictransport')}</span></Link></Menu.Item>
                    <Menu.Item key="4"><Link to={`/lowentry`} style={{ color: '#f87046'}} title={t('pageDescriptions:lowentry')}><span>{t('common:lowentry')}</span></Link></Menu.Item>
                    <Menu.Item key="5"><Link to={`/stocks`} style={{ color: '#f87046'}} title={t('pageDescriptions:stocks')}><span>{t('common:stocks')}</span></Link></Menu.Item>
                    <Menu.Item key="6"><Link to={`/parts`} style={{ color: '#f87046'}} title={t('pageDescriptions:parts')}><span>{t('common:parts')}</span></Link></Menu.Item>
                    <Menu.Item key="7"><Link to={`/news`} style={{ color: '#f87046'}} title={t('pageDescriptions:news')}><span>{t('common:news')}</span></Link></Menu.Item>
                    <Menu.Item key="8"><Link to={`/jobs`} style={{ color: '#f87046'}} title={t('pageDescriptions:jobs')}><span>{t('common:jobs')}</span></Link></Menu.Item>
                    <Menu.Item key="9"><Link to={`/manuals`} style={{ color: '#f87046'}} title={t('pageDescriptions:manuals')}><span>{t('common:manuals')}</span></Link></Menu.Item>
                    <Menu.Item key="10"><Link to={`/contact`}style={{ color: '#f87046'}} title={t('pageDescriptions:contact')}><span>{t('common:contact')}</span></Link></Menu.Item>
                  </Menu>
                </Col>
                <Col 
                  xs={{ span: 2 }}
                  sm={{ span: 1 }}
                  style={{ textAlign: 'right' }}>
                  { isAuthenticated ? <AdminMenu /> : null }
                </Col>
                <Col 
                  xs={{ span: 2 }} 
                  sm={{ span: 1 }} 
                  style={{ textAlign: 'right' }}>
                  <LanguagePicker language={i18n.language} onChange={changeLanguage} />
                </Col>
              </Row>
            </Header>
          </Affix>
          <Content style={{ padding: '0 50px' }}>
            <div className="site-layout-content">
              <Switch>
                <Route exact path="/"><Home /></Route>
                <Route exact path="/wheelchairbus"><WheelchairBus /></Route>
                <Route exact path="/wheelchaircar"><WheelchairCar /></Route>
                <Route exact path="/electrictransport"><ElectricTransport /></Route>
                <Route exact path="/lowentry"><LowEntry /></Route>
                <Route exact path="/parts"><Parts /></Route>
                <Route exact path="/partdetails/:id?"><PartDetails /></Route>
                <Route exact path="/stocks"><Stocks /></Route>
                <Route exact path="/stockdetails/:id?"><StockDetails /></Route>
                <Route exact path="/manuals"><Manuals /></Route>
                <Route exact path="/news"><News /></Route>
                <Route exact path="/jobs"><Jobs /></Route>
                <Route exact path="/jobdetails/:id?"><JobDetails /></Route>
                <Route exact path="/contact"><Contact /></Route>
                <Route exact path="/login"><Login /></Route>
                <ProtectedRoute exact path="/Admin/NewsItems"><NewsItemList /></ProtectedRoute>
                <ProtectedRoute exact path='/Admin/NewsItem/:id?'><NewsItemEditor /></ProtectedRoute>
                <ProtectedRoute exact path='/Admin/Manuals'><ManualList /></ProtectedRoute>
                <ProtectedRoute exact path='/Admin/Manual/:id?'><ManualEditor /></ProtectedRoute>
                <ProtectedRoute exact path='/Admin/Carousels'><CarouselList /></ProtectedRoute>
                <ProtectedRoute exact path='/Admin/Carousel/:id?'><CarouselEditor /></ProtectedRoute>
                <ProtectedRoute exact path='/Admin/PartCategories'><PartCategoryList /></ProtectedRoute>
                <ProtectedRoute exact path='/Admin/PartCategory/:id?'><PartCategoryEditor/></ProtectedRoute>
                <ProtectedRoute exact path='/Admin/Parts'><PartList /></ProtectedRoute>
                <ProtectedRoute exact path='/Admin/Part/:id?'><PartEditor/></ProtectedRoute>
                <ProtectedRoute exact path='/Admin/StockCategories'><StockCategoryList /></ProtectedRoute>
                <ProtectedRoute exact path='/Admin/StockCategory/:id?'><StockCategoryEditor/></ProtectedRoute>
                <ProtectedRoute exact path='/Admin/Stocks'><StockList /></ProtectedRoute>
                <ProtectedRoute exact path='/Admin/Stock/:id?'><StockEditor/></ProtectedRoute>
                <ProtectedRoute exact path='/Admin/Jobs'><JobList /></ProtectedRoute>
                <ProtectedRoute exact path='/Admin/Job/:id?'><JobEditor /></ProtectedRoute>
                <ProtectedRoute exact path='/Admin/UserInfo'><UserInfo /></ProtectedRoute>
                <Route path="/404" component={Status404} />
                <Route path='/403' component={Status403} />
                <Route path='/500' component={Status500} />
                <Route path="/:lng(en|nl|de)" exact component={Home} />
                <Route path="/:lng(en|nl|de)/wheelchairbus" exact><WheelchairBus /></Route>
                <Route path="/:lng(en|nl|de)/wheelchaircar" exact><WheelchairCar /></Route>
                <Route path="/:lng(en|nl|de)/electrictransport" exact><ElectricTransport /></Route>
                <Route path="/:lng(en|nl|de)/lowentry" exact><LowEntry /></Route>
                <Route path="/:lng(en|nl|de)/parts" exact><Parts /></Route>
                <Route path="/:lng(en|nl|de)/stocks" exact><Stocks /></Route>
                <Route path="/:lng(en|nl|de)/manuals" exact><Manuals /></Route>
                <Route path="/:lng(en|nl|de)/news" exact><News /></Route>
                <Route path="/:lng(en|nl|de)/jobs" exact><Jobs /></Route>
                <Route path="/:lng(en|nl|de)/contact" exact><Contact /></Route>
                <Route path="/:lng(en|nl|de)/login" exact><Login /></Route>
                <Route path="*"><Status404 /></Route>
                </Switch>
            </div>
          </Content>
          <Affix offsetBottom={0}>
            <Footer style={{ textAlign: 'center', paddingTop: 15, paddingBottom: 15 }}>©2020 Copyright InnoTrans B.V. | design by <a href='https://www.pinpoint-solutions.nl' title='Contact Pinpoint Solutions' style={{ color: 'white'}}>Pinpoint Solutions</a></Footer>
            <div className='bottom-social-bar'>
              <Row style={{ textAlign: 'center' }}>
                <Col span={6} style={{ backgroundColor: '#0084b1' }}>
                  <a href='http://www.linkedin.com'>
                    <Image src='/images/social-bar/linkedin.svg' style={{ maxWidth: '24px', padding: '2px' }} preview={false} fallback='/images/default.png' loading='lazy' title='LinkedIn' />
                  </a>
                </Col>
                <Col span={6} style={{ backgroundColor: '#3b5998' }}>
                  <a href='http://www.facebook.com'>
                    <Image src='/images/social-bar/facebook.svg' style={{ maxWidth: '24px', padding: '2px' }} preview={false} fallback='/images/default.png' loading='lazy' title='Facebook' />
                  </a>
                </Col>
                <Col span={6} style={{ backgroundColor: '#f44336' }}>
                  <a href='http://www.youtube.com'>
                    <Image src='/images/social-bar/youtube.svg' style={{ maxWidth: '24px', padding: '2px' }} preview={false} fallback='/images/default.png' loading='lazy' title='Youtube' />
                  </a>
                </Col>
                <Col span={6} style={{ backgroundColor: '#a8a8a8' }}>
                  <a href='mailto:info@innotrans.nl'>
                      <Image src='/images/social-bar/email.svg' style={{ maxWidth: '24px', padding: '2px' }} preview={false} fallback='/images/default.png' loading='lazy' title='Email' />
                  </a>
                </Col>
              </Row>
            </div>
          </Affix>
        </Layout>

        <BackTop style={{ right: 50, bottom: 80 }}>
            <Button shape="circle" icon={<ArrowUpOutlined />} title='Back to Top' />
        </BackTop>

      </PageLoader>
    </React.Fragment>
  )
}
