import { Breadcrumb, Button, Col, DatePicker, Form, Input, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SaveOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Image } from '../../../models/Image';
import { MultiFetcher } from '../../utilities/MultiFetcher';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { PageLoader } from '../../utilities/PageLoader';
import { Job } from '../../../models/Job';
import { useTranslation } from 'react-i18next';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css'
import { HtmlEditor } from '../_common/HtmlEditor';
import { ImageManager } from '../_common/ImageManager';

interface State {
    image?: Image
}

export const JobEditor = withAuthenticationRequired(() => { 
    const { id } = useParams<{ id: string }>()
    const { t } = useTranslation();
    const [form] = Form.useForm()
    const history = useHistory()

    const [state, setState] = useState<State>({
        image: undefined
    });
   
    const {fetchSingle: fetchJob, postSingle: postJob, isLoading} = MultiFetcher('job')
    const {postFile: uploadImage, isLoading: isLoadingImage} = MultiFetcher('image');  

    useEffect(() => {
        window.scrollTo(0, 0)      
    
        if (id) {
            const fetchData = async () => {
                const job = await fetchJob({id, disableCache: true}) as Job

                console.log(`data: ${JSON.stringify(job)}`);
                
                form.setFieldsValue({
                    id: job.id,
                    date: moment(job.date),
                    title: job.title,
                    placeOfWork: job.placeOfWork,
                    description: BraftEditor.createEditorState(job.description),
                    requirements: BraftEditor.createEditorState(job.requirements),
                    terms: BraftEditor.createEditorState(job.terms),
                    numberOfHours: job.numberOfHours,
                    workThinkLevel: job.workThinkLevel,
                    fieldOfWork: job.fieldOfWork,
                    //imageId: job.imageId
                })

                console.log(`imageId: ${job.imageId}`)

                if (job.imageId) {
                    setState(prevState => ({ ...prevState, image: { imageId: job.imageId} as Image }));
                }
                //let images : Image[] = []
                
                //images.push({ imageId: job.imageId } as Image)                
            }

            fetchData();
        } else {
            form.setFieldsValue({
                date: moment(new Date())
            })
        }
        
    }, [fetchJob, form, id])
  

    const submitJob = async (data: Job) => {
        //console.log(`data to submit: ${JSON.stringify(data)}`);
        return await postJob(data)
    }

    const Submit = async (data: Job) => {

        const storeImage = async () => {
            if (state.image){ 
                if (state.image.imageId) {
                    data.imageId = state.image.imageId
                } else {
                    data.imageId = await uploadImage(state.image.file)
                }
            }
        }

        console.log(`data.imageId: ${data.imageId}`)

        storeImage().then(() => {
            submitJob(data).then(() => {
                history.push('/admin/jobs') 
            })
        })
    }

    return (
        <React.Fragment>
           
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item>Admin</Breadcrumb.Item>
                <Breadcrumb.Item>Jobs</Breadcrumb.Item>
                <Breadcrumb.Item>{form.getFieldValue('title')}</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ width: '90%', margin: 'auto', marginBottom: '25px'}}>

                <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px'}} className={'ant-card-head-title'}>Job Details</div>

                <PageLoader loading={isLoading || isLoadingImage} subTitle={'Loading data...'}>
                    <Form
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 14 }}
                        layout="horizontal"
                        form={form}
                        onFinish={Submit}
                    >
                        <Form.Item name='id' hidden={true}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='date' label="Date" rules={[{ required: true, message: 'Please select a date!' }]}>
                            <DatePicker format='DD-MM-YYYY' />
                        </Form.Item>
                        
                        <Form.Item name='title' label="Title" rules={[{ required: true, max: 100, message: 'Please enter a title!' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='placeOfWork' label="Place of work" rules={[{ required: true, max: 50, message: 'Please enter a place of Work!' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='description' label="Description" rules={[{
                                required: true,
                                validateTrigger: 'onBlur',
                                min: 1,
                                max: 2500,
                                message: 'Please enter a Description (between 1 and 2500 characters!)'
                            }]} >
                            <HtmlEditor onChange={(editorState) => { form.setFieldsValue({description: editorState.toHTML()}) }}/>
                        </Form.Item>

                        <Form.Item name='requirements' label="Requirements" rules={[{
                                validateTrigger: 'onBlur',
                                max: 2500,
                                message: 'Please enter Requirements (between 0 and 2500 characters!)'
                            }]} >
                            <HtmlEditor onChange={(editorState) => { form.setFieldsValue({requirements: editorState.toHTML()}) }}/>
                        </Form.Item>

                        <Form.Item name='terms' label="Terms" rules={[{
                                validateTrigger: 'onBlur',
                                max: 2500,
                                message: 'Please enter Terms (between 0 and 2500 characters!)'
                            }]} >
                            <HtmlEditor onChange={(editorState) => { form.setFieldsValue({terms: editorState.toHTML()}) }}/>
                        </Form.Item>

                        <Form.Item name='numberOfHours' label="Number of hours" rules={[{ required: true, max: 50, message: 'Please enter a number of hours!' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='workThinkLevel' label="Work think level" rules={[{ required: true, max: 50, message: 'Please enter a work think level!' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='fieldOfWork' label="Field of work" rules={[{ required: true, max: 50, message: 'Please enter a Field of work!' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='imageId' label="Image">
                            <ImageManager image={state.image} onChange={(image) => { setState(prevState => ({ ...prevState, image })) }} />
                        </Form.Item>

                    </Form>

                    <Row>
                        <Col span={14} offset={6} style={{ textAlign: 'right'}}>
                            <Space>
                                <Button type='default' icon={<ArrowLeftOutlined />} onClick={() => history.push('/admin/jobs')}>{t('common:back')}</Button>
                                <Button type='primary' icon={<SaveOutlined/>} onClick={() => form.submit()}>Save</Button>
                            </Space>
                        </Col>
                    </Row>
                </PageLoader>
                    
            </div>

        </React.Fragment>
    );
})