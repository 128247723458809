import { Breadcrumb, Card, Image } from 'antd';
import React, { useEffect } from 'react';
import 'react-alice-carousel/lib/alice-carousel.css'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
// import { useParams } from 'react-router-dom';
import i18n from '../i18n';
import { CardSingle } from './utilities/CardSingle';
import { CarouselViewer } from './utilities/Carousel';

export const ElectricTransport = () => { 

  const { t } = useTranslation();
  // let { lng } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
    return (
      <React.Fragment>
        <Helmet>
            <title>InnoTrans | {t('common:electrictransport')}</title>
            <meta name="description" content={t('pageDescriptions:electrictransport')} />
            <meta name="keywords" content={t('pageKeywords:electrictransport')} />
            <meta httpEquiv="content-language" content={i18n.language?.substr(0,2)} />
            {/* <link rel="canonical" href={`https://innotrans.nl${lng ? `/${lng}` : ''}/wheelchaircar`} /> */}
        </Helmet>
        
         <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
            <Breadcrumb.Item><h1>{t('common:electrictransport')}</h1></Breadcrumb.Item>
        </Breadcrumb>

        <CarouselViewer id='10cb198c-832f-4202-b9a5-8429dea87b57' />

        <CardSingle title={t('electrictransport:card1-title')}>
          <div dangerouslySetInnerHTML={{__html: t('electrictransport:card1-text') }} />
          <br/>
          <br/>
          <div>
            <div className='bullet-list' style={{ lineHeight: 2}}>
              <div className='bullet-item'>
                <img src={'/images/brands/citroen.png'} alt='' className='bullet-image-larger'/> Citroen eJumper 37/70 kWh<br/>
              </div>
              <div className='bullet-item'>
                <img src={'/images/brands/fiat.png'} alt='' className='bullet-image-larger'/> Fiat eDucato 47/79 kWh<br/>
              </div>
              <div className='bullet-item'>
                <img src={'/images/brands/peugeot.png'} alt='' className='bullet-image-larger'/> Peugeot eBoxer 37/75 kWh<br/>
              </div>
            </div>
          </div>
        </CardSingle>

        <CardSingle title={t('electrictransport:card2-title')}>
          <div dangerouslySetInnerHTML={{__html: t('electrictransport:card2-text') }} />
          <br/>
          <br/>
          <div>
            <div className='bullet-list' style={{ lineHeight: 2}}>
              <div className='bullet-item'>
                <img src={'/images/brands/mercedes.png'} alt='' className='bullet-image-larger' /> Mercedes-Benz eVito/eQV (90 kWh)<br/>
              </div>
              <div className='bullet-item'>
                <img src={'/images/brands/citroen.png'} alt='' className='bullet-image-larger'/> Citroen e-Space Tourer (eJumpy) 50/75 kWh<br/>
              </div>
              <div className='bullet-item'>
                <img src={'/images/brands/peugeot.png'} alt='' className='bullet-image-larger'/> Peugeot e-Traveller (e-expert) 50/75 kWh<br/>
              </div>
              <div className='bullet-item'>
                <img src={'/images/brands/opel.png'} alt='' className='bullet-image-larger'/> Opel Zafira e-life (vivaro-e) 50/75 kWh<br/>
              </div>
              <div className='bullet-item'>
                <img src={'/images/brands/toyota.jpg'} alt='' className='bullet-image-larger'/> Toyota Proace electric 500/75 kWh<br/>
              </div>
              <div className='bullet-item'>
                <img src={'/images/brands/nissan.png'} alt='' className='bullet-image-larger' /> Nissan e-NV 200 40 kWh<br/>
              </div>
            </div>
          </div>
        </CardSingle>

      </React.Fragment>
    )
}