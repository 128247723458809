import { Breadcrumb, Card, Menu, Empty, Row, Col } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import i18n from '../i18n';
import { MultiFetcher } from './utilities/MultiFetcher';
import { PageLoader } from './utilities/PageLoader';
import * as _ from 'lodash';
import { ParseMlText } from './utilities/MlTextHelper';
import Meta from 'antd/lib/card/Meta';
import { ImageFromId } from './utilities/ImageFromId';
import { Stock } from '../models/Stock';
import { StockCategory } from '../models/StockCategory';

interface State {
    categories: StockCategory[]
    selectedCategoryId?: string
    stocks: Stock[],
}

export const Stocks = () => { 

    const { t } = useTranslation();
    // let { lng } = useParams();
    const history = useHistory();
    const language = i18n.language

    const [state, setState] = useState<State>({
        categories: [],
        selectedCategoryId: undefined,
        stocks: []
      });

    const {fetchMultiple: fetchCategories, isLoading: isLoadingCategories} = MultiFetcher('stockcategory');   
    const {fetchMultiple: fetchStocks, isLoading: isLoadingStocks} = MultiFetcher('stock');   

    const fetchCategoriesAsync = useCallback(async () => {
        const categories = await fetchCategories({});
        setState(prevState => ({ ...prevState, categories }));
        //console.log(`data: ${JSON.stringify(categories)}`); 
    }, [fetchCategories]);

    const fetchStocksAsync = useCallback(async () => {
        const queryParams = state.selectedCategoryId ? `categoryId=${state.selectedCategoryId}` : undefined;
        const stocks = await fetchStocks({queryParams});
        setState(prevState => ({ ...prevState, stocks: stocks.filter(part => part.visible === true) }));
        //console.log(`data: ${JSON.stringify(stocks)}`); 
    }, [fetchStocks, state.selectedCategoryId]);

    
    useEffect(() => {
        window.scrollTo(0, 0)
        fetchCategoriesAsync()
      }, [fetchCategoriesAsync])

    useEffect(() => {
        fetchStocksAsync()
    }, [fetchStocksAsync])

    return (
        <React.Fragment>
            <Helmet>
                <title>InnoTrans | {t('common:stocks')}</title>
                <meta name="description" content={t('pageDescriptions:stocks')} />
                <meta name="keywords" content={t('pageKeywords:stocks')} />
                <meta httpEquiv="content-language" content={i18n.language?.substr(0,2)} />
                {/* <link rel="canonical" href={`https://innotrans.nl${lng ? `/${lng}` : ''}/stocks`} /> */}
            </Helmet>
            
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item><h1>{t('common:stocks')}</h1></Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px'}} className={'ant-card-head-title'}>{t('common:stocks')}</div>

            <Row>
                <Col 
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 9, offset: 0 }}
                    md={{ span: 6, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 4, offset: 1 }}>
                    <PageLoader loading={isLoadingCategories}>
                        <Menu 
                            theme='light' 
                            mode='inline' 
                            className='catMenu'
                            activeKey={state.selectedCategoryId}
                            selectedKeys={state.selectedCategoryId ? [state.selectedCategoryId] : []}>
                                { _.map(state.categories, (rootCat) => {
                                    return <Menu.Item 
                                                key={rootCat.id}
                                                style={{ color: 'white'}}
                                                className='bg-blue'
                                                onClick={(item) => setState(prevState => ({ ...prevState, selectedCategoryId: item.key.toString() }))}>
                                                    {ParseMlText(rootCat.name)[language]}
                                            </Menu.Item>
                                })}
                        </Menu>
                    </PageLoader>
                </Col>
                <Col 
                    xs={{ span: 24, offset: 0 }}    
                    sm={{ span: 14, offset: 1 }}
                    md={{ span: 15, offset: 1 }}
                    lg={{ span: 16, offset: 1 }}
                    xl={{ span: 17, offset: 1 }}>
                    <PageLoader loading={isLoadingStocks}>
                        <div className='content-flex' style={{ paddingBottom: 25, justifyContent: 'left' }}>
                            { (state.stocks.length === 0)  ? 
                                <Empty />
                            : _.map(state.stocks, (stock, index) => {
                                return (
                                <Card
                                    key={index}
                                    hoverable
                                    style={{ width: 240, marginRight: 20, marginBottom: 20 }}
                                    cover={<ImageFromId imageId={stock.imageIds[0]} preview={false} />}
                                    onClick={ () => history.push(`/stockdetails/${stock.id}`)} >
                                    <Meta title={ParseMlText(stock.name)[language]} description={`€ ${Intl.NumberFormat('nl-NL').format(stock.price ?? 0)}`} />
                                </Card>)
                            })}
                        </div>
                    
                    </PageLoader>
                </Col>
            </Row>
        </React.Fragment>
    );
}
