import { Breadcrumb, Space, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { MultiFetcher } from '../../utilities/MultiFetcher';
import { ParseMlText } from '../../utilities/MlTextHelper';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { AddButton, DeleteButton, EditButton } from '../_common/CustomButtons';

interface State {
    manuals: any;
  }

export const ManualList = withAuthenticationRequired(() => { 
   
    const [state, setState] = useState<State>({
        manuals: []
      });

    const {fetchMultiple: fetchManuals, deleteSingle: deleteManual, isLoading} = MultiFetcher('manual');   

    const fetchData = useCallback(async () => {
        const manuals = await fetchManuals({disableCache: true});
        setState(prevState => ({ ...prevState, manuals }));

        //console.log(`data: ${JSON.stringify(newsItems)}`); 
    }, [fetchManuals]);

    useEffect(() => {
        window.scrollTo(0, 0);

        fetchData();

    }, [fetchData]);

    const deleteItem = async (id: string) => {
        deleteManual(id).then(async () => await fetchData());
    }

    return (
        <React.Fragment>
            
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item>Admin</Breadcrumb.Item>
                <Breadcrumb.Item>Manuals</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ width: '80%', margin: 'auto', marginBottom: '25px'}}>

                <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px'}} className={'ant-card-head-title'}>Manuals</div>

                <Table 
                    loading={isLoading}
                    rowKey={'id'}
                    columns={[
                        // {
                        //     title: 'Id',
                        //     dataIndex: 'id',
                        //     key: 'id',
                        // },
                        {
                            title: 'Order',
                            dataIndex: 'order',
                            key: 'order',
                        },
                        {
                            title: 'Type',
                            dataIndex: 'type',
                            key: 'type',
                            render: (type) => {
                                switch (type) {
                                    case 0: return 'Pdf';
                                    case 1: return 'YouTube';
                                    default: return 'n/a';
                                  }
                            }
                        },
                        {
                            title: 'Title (English)',
                            dataIndex: 'title',
                            key: 'title',
                            render: (title) => <span>{ParseMlText(title)['en']}</span>
                        },
                        {
                            title: 'YouTube Code',
                            dataIndex: 'youTubeCode',
                            key: 'youTubeCode',
                        },
                        {
                            title: 'Document Name',
                            dataIndex: 'documentName',
                            key: 'documentName',
                        },
                        {
                            key: 'actions',
                            align: 'right',
                            render: (record: any) => 
                                <Space>
                                    <EditButton url={`/Admin/Manual/${record.id}`} />
                                    <DeleteButton id={record.id} onDelete={deleteItem} />
                                </Space>
                        }
                            
                    ]} 
                    dataSource={state.manuals} />
                    <Space>
                        <AddButton url='/Admin/Manual/' />
                    </Space>

            </div>

        </React.Fragment>
    );
})