import { Breadcrumb, Card, Image } from 'antd';
import React, { useEffect } from 'react';
import 'react-alice-carousel/lib/alice-carousel.css'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
// import { useParams } from 'react-router-dom';
import i18n from '../i18n';
import { CardDouble } from './utilities/CardDouble';
import { CardImageLeft } from './utilities/CardImageLeft';
import { CardImageRight } from './utilities/CardImageRight';
import { CardSingle } from './utilities/CardSingle';
import { CarouselViewer } from './utilities/Carousel';

export const WheelchairBus = () => { 

  const { t } = useTranslation();
  // let { lng } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
    return (
      <React.Fragment>
        <Helmet>
            <title>InnoTrans | {t('common:wheelchairbus')}</title>
            <meta name="description" content={t('pageDescriptions:wheelchairbus')} />
            <meta name="keywords" content={t('pageKeywords:wheelchairbus')} />
            <meta httpEquiv="content-language" content={i18n.language?.substr(0,2)} />
            {/* <link rel="canonical" href={`https://innotrans.nl${lng ? `/${lng}` : ''}/wheelchairbus`} /> */}
        </Helmet>
        
         <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
            <Breadcrumb.Item><h1>{t('common:wheelchairbus')}</h1></Breadcrumb.Item>
        </Breadcrumb>

        <CarouselViewer id='a6a73f71-7139-4dc2-bd59-e162576cc3ad' />

        <CardSingle title={t('wheelchairbus:card1-title')}>
          <div dangerouslySetInnerHTML={{__html: t('wheelchairbus:card1-text') }} />
        </CardSingle>

        <CardImageLeft title={t('wheelchairbus:card2-title')} image='/images/wheelchairbus/controlpanel.jpg'>
          <div dangerouslySetInnerHTML={{__html: t('wheelchairbus:card2-text') }} />
        </CardImageLeft>
        
        <CardImageRight title={t('wheelchairbus:card3-title')} image='/images/wheelchairbus/retractorlock.jpg'>
          <div dangerouslySetInnerHTML={{__html: t('wheelchairbus:card3-text') }} />
        </CardImageRight>
        
        <CardImageLeft title={t('wheelchairbus:card4-title')} image='/images/wheelchairbus/longbeltties.jpg'>
          <div dangerouslySetInnerHTML={{__html: t('wheelchairbus:card4-text') }} />
        </CardImageLeft>

        <CardImageRight title={t('wheelchairbus:card5-title')} image='/images/wheelchairbus/threepointsafetybelt.jpg'>
          <div dangerouslySetInnerHTML={{__html: t('wheelchairbus:card5-text') }} />
        </CardImageRight>

        <CardImageLeft title={t('wheelchairbus:card6-title')} image='/images/wheelchairbus/flatwheelchairaccess.jpg'>
          <div dangerouslySetInnerHTML={{__html: t('wheelchairbus:card6-text') }} />
        </CardImageLeft>

        <CardImageRight title={t('wheelchairbus:card7-title')} image='/images/wheelchairbus/chairs.jpg'>
          <div dangerouslySetInnerHTML={{__html: t('wheelchairbus:card7-text') }} />
        </CardImageRight>

        <CardImageLeft title={t('wheelchairbus:card8-title')} image='/images/wheelchairbus/floorlightning.jpg'>
          <div dangerouslySetInnerHTML={{__html: t('wheelchairbus:card8-text') }} />
        </CardImageLeft>

        <CardDouble
          title1={t('wheelchairbus:card9-title')}
          children1={
            <span>
              <div dangerouslySetInnerHTML={{__html: t('wheelchairbus:card9-text') }} />
            </span>
          }
          children2={
            <div>
              <div className='bullet-list' style={{ lineHeight: 2}}>
                <div className='bullet-item'>
                  <img src={'/images/brands/mercedes.png'} alt='' className='bullet-image-larger' loading='lazy' /> Mercedes-Benz Sprinter<br/>
                </div>
                <div className='bullet-item'>
                  <img src={'/images/brands/vw.jpg'} alt='' className='bullet-image-larger' loading='lazy'/> Volkswagen Crafter<br/>
                </div>
                <div className='bullet-item'>
                  <img src={'/images/brands/man.png'} alt='' className='bullet-image-larger' loading='lazy'/> MAN TGE<br/>
                </div>
                <div className='bullet-item'>
                  <img src={'/images/brands/fiat.png'} alt='' className='bullet-image-larger' loading='lazy'/> Fiat Ducato<br/>
                </div>
                <div className='bullet-item'>
                  <img src={'/images/brands/citroen.png'} alt='' className='bullet-image-larger' loading='lazy'/> Citroen Jumper<br/>
                </div>
                <div className='bullet-item'>
                  <img src={'/images/brands/peugeot.png'} alt='' className='bullet-image-larger' loading='lazy'/> Peugeot Boxer<br/>
                </div>
              </div>
            </div>
          }/>

        <CardImageLeft 
          title={t('wheelchairbus:card10-title')} 
          image='/images/wheelchairbus/floorarrangement.jpg'>
          <div dangerouslySetInnerHTML={{__html: t('wheelchairbus:card10-text') }} />
          <br/>
          <div className='content-flex' style={{ marginBottom: '10px'}}> 
            <Card hoverable style={{ width: 100, marginBottom: 10 }}>
              <Image src='/images/wheelchairbus/floorplans/plan1.png' preview={true} loading='lazy'/>
            </Card>
            <Card hoverable style={{ width: 100, marginBottom: 10 }}>
              <Image src='/images/wheelchairbus/floorplans/plan2.png' preview={true} loading='lazy'/>
            </Card>
            <Card hoverable style={{ width: 100, marginBottom: 10 }}>
              <Image src='/images/wheelchairbus/floorplans/plan4.png' preview={true} loading='lazy'/>
            </Card>
            <Card hoverable style={{ width: 100, marginBottom: 10 }}>
              <Image src='/images/wheelchairbus/floorplans/plan5.png' preview={true} loading='lazy'/>
            </Card>
          </div>
          <div className='content-flex' style={{ marginBottom: '10px'}}> 
            <Card hoverable style={{ width: 100, marginBottom: 10 }}>
              <Image src='/images/wheelchairbus/floorplans/plan3.png' preview={true} loading='lazy'/>
            </Card>
            <Card hoverable style={{ width: 100, marginBottom: 10 }}>
              <Image src='/images/wheelchairbus/floorplans/plan6.png' preview={true} loading='lazy'/>
            </Card>
            <Card hoverable style={{ width: 100, marginBottom: 10 }}>
              <Image src='/images/wheelchairbus/floorplans/plan7.png' preview={true} loading='lazy'/>
            </Card>
            <Card hoverable style={{ width: 100, marginBottom: 10 }}>
              <Image src='/images/wheelchairbus/floorplans/plan8.png' preview={true} loading='lazy'/>
            </Card>
          </div>
          
        </CardImageLeft>

      </React.Fragment>
    )
}