import { Breadcrumb, Button, Popconfirm, Space, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { MultiFetcher } from '../../utilities/MultiFetcher';
import { ParseMlText } from '../../utilities/MlTextHelper';
import { withAuthenticationRequired } from '@auth0/auth0-react';

interface State {
    newsItems: any;
  }

export const NewsItemList = withAuthenticationRequired(() => { 

    const history = useHistory();
    
    const [state, setState] = useState<State>({
        newsItems: []
      });

    const {fetchMultiple: fetchNewsItems, deleteSingle: deleteNewsItem, isLoading} = MultiFetcher('newsitem');   

    const fetchData = useCallback(async () => {
        const newsItems = await fetchNewsItems({disableCache: true});
        setState(prevState => ({ ...prevState, newsItems }));

        //console.log(`data: ${JSON.stringify(newsItems)}`); 
    }, [fetchNewsItems]);

    useEffect(() => {
        window.scrollTo(0, 0);

        fetchData();

    }, [fetchData]);

    const deleteItem = async (id: string) => {
        deleteNewsItem(id).then(async () => await fetchData());
    }

    return (
        <React.Fragment>
            
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item>Admin</Breadcrumb.Item>
                <Breadcrumb.Item>NewsItems</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ width: '80%', margin: 'auto', marginBottom: '25px'}}>

                <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px'}} className={'ant-card-head-title'}>NewsItems</div>

                <Table 
                    loading={isLoading}
                    rowKey={'id'}
                    columns={[
                        // {
                        //     title: 'Id',
                        //     dataIndex: 'id',
                        //     key: 'id',
                        // },
                        {
                            title: 'Date',
                            dataIndex: 'date',
                            key: 'date',
                            render: (date) => <span>{moment(date).format('DD-MM-YYYY')}</span>
                        },
                        {
                            title: 'Type',
                            dataIndex: 'type',
                            key: 'type',
                            render: (type) => {
                                switch (type) {
                                    case 0: return 'Photo';
                                    case 1: return 'YouTube';
                                    default: return 'n/a';
                                  }
                            }
                        },
                        {
                            title: 'Title (English)',
                            dataIndex: 'title',
                            key: 'title',
                            render: (title) => <span>{ParseMlText(title)['en']}</span>
                        },
                        {
                            title: 'YouTube Code',
                            dataIndex: 'youTubeCode',
                            key: 'youTubeCode',
                        },
                        {
                            title: '# Photos',
                            dataIndex: 'imageIds',
                            key: 'imageIds',
                            render: (imageIds: string[]) => <span>{imageIds?.length ?? 0}</span>
                        },
                        {
                            key: 'actions',
                            align: 'right',
                            render: (record: any) => 
                                <Space>
                                    <Button size='small' icon={<EditOutlined />} title='Edit' onClick={() => history.push(`/admin/newsitem/${record.id}`)} />
                                    <Popconfirm
                                        title='Are you sure you want to delete this news item?'
                                        onCancel={(e) => e?.stopPropagation()}
                                        onConfirm={(e) => {
                                            e?.stopPropagation();
                                            deleteItem(record.id);
                                        }}>
                                        <Button size='small' danger icon={<DeleteOutlined />} title='Delete News item' onClick={e => e.stopPropagation()} />
                                    </Popconfirm>
                                </Space>
                        }
                            
                    ]} 
                    dataSource={state.newsItems} />
                    <Space>
                        <Button type='primary' icon={<PlusOutlined/>} onClick={() => history.push(`/admin/newsitem/`)} >Add</Button>
                    </Space>

            </div>

        </React.Fragment>
    );
})