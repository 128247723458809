import { Result } from "antd";
import React from "react";
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
    loading: boolean;
    title?: string;
    subTitle?: string;
  }

  export const PageLoader: React.FunctionComponent<Props> = (props) => {
    return (
      <React.Fragment>
        {props.loading
          ? <Result
            icon={<LoadingOutlined style={{ fontSize: 48 }} spin />}
            title={props.title}
            subTitle={props.subTitle}>
          </Result>
          : props.children
        }
      </React.Fragment>
    );
  };