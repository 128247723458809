import React from 'react';
import {  Form, Select } from 'antd';
import { PageLoader } from '../../utilities/PageLoader';
import * as _ from 'lodash';
import { PartCategory } from '../../../models/PartCategory';
import { ParseMlText } from '../../utilities/MlTextHelper';

interface Props {
    categories: PartCategory[];
    isLoading: boolean;
    required: boolean;
    onChange?(value: string): void;
}

export const PartCategoriesDropDown = (props: Props) => {

    const getDisplayName = (name: string, parentName: string ) : string => {
        const nameEn = ParseMlText(name)['en'];
        const parentNameEn = ParseMlText(parentName)['en'];
        return parentNameEn ? `${parentNameEn} / ${nameEn}` : nameEn;
    }
    
    return (
        <PageLoader loading={props.isLoading} subTitle={'Loading Categories'}>
            <Form.Item name='categoryId' label="Category" rules={ props.required ? [{ required: true, message: 'Please select a category!' }] : []}> 
                <Select onSelect={props.onChange}  style={{maxWidth: 400}}>
                    <Select.Option key='0' value=''>&nbsp;</Select.Option>
                    { _.map(props.categories, (cat, index) => {
                        return <Select.Option key={`cat_${index}`} value={cat.id}>{getDisplayName(cat.name, cat.parentName)}</Select.Option>
                    })}
                </Select>
            </Form.Item>
        </PageLoader>
    );
}