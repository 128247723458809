export class Manual {
    public id: string
    public order: number
    public type: number
    public title: string
    public description: string
    public youTubeCode: string
    public documentId: string
    public documentName: string
}

export enum ManualType {
    Pdf = 0,
    Youtube = 1
}