import { Menu } from "antd";
import React from "react";
import { Dropdown } from 'antd';

interface Props {
    language: string;
    onChange(language: string): void;
}

export const LanguagePicker = (props: Props) => { 

    const getLanguageName = (language: string) => {
        switch (language) {
            case 'nl': return 'Dutch'; 
            case 'en': return 'English';
            case 'de': return 'German';
        }
    }

    const languageItems = (
        <Menu onClick={item => props.onChange(item.key.toString()) } >
            { props.language !== 'nl' ? <Menu.Item key='nl'><img src='/images/flags/nl.svg' alt='Dutch' style={{ width: '20px' }}/></Menu.Item> : null}
            { props.language !== 'en' ? <Menu.Item key='en'><img src='/images/flags/en.svg' alt='English' style={{ width: '20px' }}/></Menu.Item> : null}
            { props.language !== 'de' ? <Menu.Item key='de'><img src='/images/flags/de.svg' alt='German' style={{ width: '20px' }}/></Menu.Item> : null}
        </Menu>
    );

    return (
        <React.Fragment>
            <Dropdown 
                overlay={languageItems} 
                trigger={['click']} 
                placement={"bottomCenter"} 
                overlayStyle={{ paddingTop: '10px'}}>
                    <img src={`/images/flags/${props.language.substr(0,2)}.svg`} alt={getLanguageName(props.language.substr(0,2))} style={{ width: '20px', verticalAlign: 'text-top', marginTop: -5, cursor: 'pointer' }}/>
            </Dropdown>
      </React.Fragment>
    );
}
