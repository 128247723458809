import { Breadcrumb, Space, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { MultiFetcher } from '../../utilities/MultiFetcher';
import { ParseMlText } from '../../utilities/MlTextHelper';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { PartCategory } from '../../../models/PartCategory';
import { AddButton, DeleteButton, EditButton } from '../_common/CustomButtons';

interface State {
    categories: PartCategory[]
  }

export const StockCategoryList = withAuthenticationRequired(() => {    
    const [state, setState] = useState<State>({
        categories: [],
      });

    const {fetchMultiple: fetchCategories, deleteSingle: deleteCategory, isLoading} = MultiFetcher('stockCategory');   

    const fetchData = useCallback(async () => {
        const categories = await fetchCategories({disableCache:true});
        setState(prevState => ({ ...prevState, categories }));
        //console.log(`data: ${JSON.stringify(categories)}`); 
    }, [fetchCategories]);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, [fetchData]);

    const deleteItem = async (id: string) => {
        deleteCategory(id).then(async () => await fetchData());
    }

    return (
        <React.Fragment>
            
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item>Admin</Breadcrumb.Item>
                <Breadcrumb.Item>StockCategories</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ width: '80%', margin: 'auto', marginBottom: '25px'}}>

                <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px'}} className={'ant-card-head-title'}>Stock Categories</div>

                <Table 
                    loading={isLoading}
                    rowKey={'id'}
                    columns={[
                        // {
                        //     title: 'Id',
                        //     dataIndex: 'id',
                        //     key: 'id',
                        // },
                        // {
                        //     title: 'Parent (English)',
                        //     dataIndex: 'parentName',
                        //     key: 'parentName',
                        //     render: (parentName) => <span>{ParseMlText(parentName)['en']}</span>
                        // },
                        {
                            title: 'Name (English)',
                            dataIndex: 'name',
                            key: 'name',
                            render: (name) => <span>{ParseMlText(name)['en']}</span>
                        },
                        {
                            key: 'actions',
                            align: 'right',
                            render: (record: any) => 
                                <Space>
                                    <EditButton url={`/Admin/StockCategory/${record.id}`} />
                                    <DeleteButton id={record.id} onDelete={deleteItem} />
                                </Space>
                        }
                            
                    ]} 
                    dataSource={state.categories} />
                    <Space>
                        <AddButton url='/Admin/StockCategory/' />                        
                    </Space>

            </div>

        </React.Fragment>
    );
})