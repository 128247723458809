import { Breadcrumb } from 'antd';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
// import { useParams } from 'react-router-dom';
import i18n from '../i18n';
import { Manual } from '../models/Manual';
import { ManualItem } from './utilities/ManualItem';
import { MultiFetcher } from './utilities/MultiFetcher';
import { PageLoader } from './utilities/PageLoader';

interface State {
    manuals: Manual[];
}

export const Manuals = () => { 
    const { t } = useTranslation();
    // let { lng } = useParams();

    const [state, setState] = useState<State>({
        manuals: []
      });

    const {fetchMultiple: fetchManuals, isLoading} = MultiFetcher('manual');   

    const fetchData = useCallback(async () => {
        const manuals = await fetchManuals({});
        setState(prevState => ({ ...prevState, manuals }));
        //console.log(`data: ${JSON.stringify(newsItems)}`); 
    }, [fetchManuals]);


    useEffect(() => {
        window.scrollTo(0, 0)
        fetchData()
      }, [fetchData])

    return (
        <React.Fragment>
            <Helmet>
                <title>InnoTrans | {t('common:manuals')}</title>
                <meta name="description" content={t('pageDescriptions:manuals')} />
                <meta name="keywords" content={t('pageKeywords:manuals')} />
                <meta httpEquiv="content-language" content={i18n.language?.substr(0,2)} />
                {/* <link rel="canonical" href={`https://innotrans.nl${lng ? `/${lng}` : ''}/manuals`} /> */}
            </Helmet>
            
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item><h1>{t('common:manuals')}</h1></Breadcrumb.Item>
            </Breadcrumb>

            <PageLoader loading={isLoading} subTitle={'Loading data...'}>
                {_.map(state.manuals, (manual, index) => {
                    return <ManualItem key={index} manual={manual} />
                })}
            </PageLoader>
        </React.Fragment>
    );
}
