// @ts-check
import { Card, Col, Descriptions, Row } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Job } from '../../models/Job';
import { ImageFromId } from './ImageFromId';

interface Props {
    job: Job
}

export const JobItem = (props: Props) => {
    const history = useHistory();

    return(
        <div className='' style={{ paddingTop: '10px'}}>
            <Card
                bordered={false} 
                className='card card-double margin-top-bottom-20'
                style={{ width: '80vw', minWidth: 300, maxWidth: 840 }}
                hoverable
                onClick={ () => history.push(`/jobdetails/${props.job.id}`)} 
                title={
                    <Row>
                        <Col xs={{ span: 24 }} sm={{ span: 19 }} style={{ textAlign: 'left' }}>
                            <Paragraph style={{color: '#f87046', marginBottom: 0 }} ellipsis>{props.job.title}</Paragraph>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 5 }} style={{ textAlign: 'right', fontSize: 14, color: 'gray', marginTop: 8 }}>{moment(props.job.date).format('DD-MM-YYYY')}</Col>
                    </Row>  
                }>
                    <Row>
                        <Col xs={{ span: 24 }} sm={{ span: 7 }} lg={{ span: 10 }} style={{ textAlign: 'left' }}>
                            <ImageFromId imageId={props.job.imageId} preview={false} />
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 16, offset: 1 }} lg={{ span: 13, offset: 1 }}>
                            <Descriptions layout="horizontal" column={1} bordered className="th-width-40perc">
                                <Descriptions.Item label="Plaats">{props.job.placeOfWork}</Descriptions.Item>
                                <Descriptions.Item label="Uren">{props.job.numberOfHours}</Descriptions.Item>
                                <Descriptions.Item label="Werk & Denkniveau">{props.job.workThinkLevel}</Descriptions.Item>
                                <Descriptions.Item label="Vakgebied">{props.job.fieldOfWork}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
            </Card>
        </div>
    )
}