import { Breadcrumb, Row, Col, Space, Button, Descriptions } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import i18n from '../i18n';
import { Stock } from '../models/Stock';
import { MultiFetcher } from './utilities/MultiFetcher';
import { PageLoader } from './utilities/PageLoader';
import * as _ from 'lodash';
import { ParseMlText } from './utilities/MlTextHelper';
import { ImageFromId } from './utilities/ImageFromId';
import AliceCarousel from 'react-alice-carousel'
import { ArrowLeftOutlined  } from '@ant-design/icons';
import { GetPriceTypeDisplayName, GetTransmissionTypeDisplayName } from './utilities/EnumHelper';

interface State {
    stock?: Stock; 
}

export const StockDetails = () => { 

    const { t } = useTranslation();
    const { id } = useParams();
    const history = useHistory();
    const language = i18n.language

    const [state, setState] = useState<State>({
        stock: undefined
    });

    const {fetchSingle: fetchStock, isLoading} = MultiFetcher('stock');   

    const fetchStockAsync = useCallback(async () => {
        const stock = await fetchStock({id});
        setState(prevState => ({ ...prevState, stock }));
        //console.log(`data: ${JSON.stringify(stocks)}`); 
        console.log(`imageIds: ${JSON.stringify(stock.imageIds)}`)
    }, [fetchStock, id]);

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchStockAsync()
      }, [fetchStockAsync])

    return (
        <React.Fragment>
            <Helmet>
                <title>InnoTrans | {t('common:stocks')}</title>
                <meta name="description" content={t('pageDescriptions:stocks')} />
                <meta name="keywords" content={t('pageKeywords:stocks')} />
                <meta httpEquiv="content-language" content={i18n.language?.substr(0,2)} />
                {/* <link rel="canonical" href={`https://innotrans.nl${lng ? `/${lng}` : ''}/stocks`} /> */}
            </Helmet>
            
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item><h1>{t('common:stocks')}</h1></Breadcrumb.Item>
            </Breadcrumb>

            <PageLoader loading={isLoading}>
                <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px'}} className={'ant-card-head-title'}>{ ParseMlText(state.stock?.name)[language]}</div>
                <Row>
                    <Col 
                        xs={{ span: 22, offset: 1 }}
                        md={{ span: 9, offset: 1 }}
                        lg={{ span: 8, offset: 1 }}
                        xl={{ span: 7, offset: 1 }}>
                        <AliceCarousel 
                            autoPlay={false}
                            duration={2000}
                            responsive={{ 0: { items: 1 } }}
                            buttonsDisabled={false}
                            dotsDisabled={false}
                            mouseTrackingEnabled={false}
                            autoHeight={false}
                            playButtonEnabled={false}
                            stagePadding={{
                                paddingLeft: 0,
                                paddingRight: 0
                            }}>
                                { _.map(state.stock?.imageIds, (imageId, index) => {
                                    return  <ImageFromId key={index} imageId={imageId} preview={true} style={{ maxHeight: 800 }} />
                                })}
                        </AliceCarousel>
                    </Col>
                    <Col 
                        xs={{ span: 22, offset: 1 }}
                        md={{ span: 12, offset: 1 }}
                        lg={{ span: 13, offset: 1 }}
                        xl={{ span: 14, offset: 1 }}>
                            <Descriptions bordered column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}>
                                <Descriptions.Item label={t('common:category')} span={2} style={{ verticalAlign: 'top' }}>{ ParseMlText(state.stock?.categoryName)[language]}</Descriptions.Item>
                                <Descriptions.Item label={t('common:description')} span={2} style={{ verticalAlign: 'top' }}>
                                    <div dangerouslySetInnerHTML={{__html: state.stock ? ParseMlText(state.stock.description)[language]?.replaceAll('\n', '<br>'): '' }} />
                                </Descriptions.Item>
                                <Descriptions.Item label={t('common:yearManufactured')} style={{ verticalAlign: 'top', width: '25%' }}>{state.stock?.yearManufactured ?? '-'}</Descriptions.Item>
                                <Descriptions.Item label={t('common:kilometers')} style={{ verticalAlign: 'top' }}>{state.stock?.kilometers ?? '-'}</Descriptions.Item>
                                <Descriptions.Item label={t('common:wheelbase')} style={{ verticalAlign: 'top' }}>{state.stock?.wheelbase ?? '-'}</Descriptions.Item>
                                <Descriptions.Item label={t('common:weight')} style={{ verticalAlign: 'top' }}>{state.stock?.weight ?? '-'}</Descriptions.Item>
                                <Descriptions.Item label={t('common:transmissionType')} style={{ verticalAlign: 'top' }}>{GetTransmissionTypeDisplayName(state.stock?.transmissionType)}</Descriptions.Item>
                                <Descriptions.Item label={t('common:numberOfWheelchairs')} style={{ verticalAlign: 'top' }}>{state.stock?.numberOfWheelchairs ?? '-'}</Descriptions.Item>
                                <Descriptions.Item label={t('common:lift')} span={2} style={{ verticalAlign: 'top' }}>
                                    <div dangerouslySetInnerHTML={{__html: ParseMlText(state.stock?.lift)[language]?.replaceAll('\n', '<br>')}} />
                                </Descriptions.Item>
                                <Descriptions.Item label={t('common:entry')} span={2} style={{ verticalAlign: 'top' }}>
                                    <div dangerouslySetInnerHTML={{__html: ParseMlText(state.stock?.entry)[language]?.replaceAll('\n', '<br>')}} />
                                </Descriptions.Item>
                                <Descriptions.Item label={t('common:price')} style={{ verticalAlign: 'top' }}>{`€ ${Intl.NumberFormat('nl-NL').format(state.stock?.price ?? 0)}`}</Descriptions.Item>
                                <Descriptions.Item label={t('common:priceType')} style={{ verticalAlign: 'top' }}>{GetPriceTypeDisplayName(state.stock?.priceType)}</Descriptions.Item>
                            </Descriptions>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 25}}>
                    <Col xs={{ span: 22, offset: 1 }} style={{ marginTop: 25, textAlign: 'left'}}>
                        <Space>
                            <Button type='default' icon={<ArrowLeftOutlined />} onClick={() => history.push('/Stocks')}>{t('common:back')}</Button>
                        </Space>
                    </Col>
                </Row>
            </PageLoader>
        </React.Fragment>
    );
}
