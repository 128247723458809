import React from 'react';
import {  Form, Select } from 'antd';
import { PageLoader } from '../../utilities/PageLoader';
import * as _ from 'lodash';
import { ParseMlText } from '../../utilities/MlTextHelper';
import { StockCategory } from '../../../models/StockCategory';

interface Props {
    categories: StockCategory[];
    isLoading: boolean;
    required: boolean;
    onChange?(value: string): void;
}

export const StockCategoriesDropDown = (props: Props) => {
    return (
        <PageLoader loading={props.isLoading} subTitle={'Loading Categories'}>
            <Form.Item name='categoryId' label="Category" rules={ props.required ? [{ required: true, message: 'Please select a category!' }] : []}> 
                <Select onSelect={props.onChange} style={{ maxWidth: 250 }}>
                    <Select.Option key='0' value=''>&nbsp;</Select.Option>
                    { _.map(props.categories, (cat, index) => {
                        return <Select.Option key={`cat_${index}`} value={cat.id}>{ParseMlText(cat.name)['en']}</Select.Option>
                    })}
                </Select>
            </Form.Item>
        </PageLoader>
    );
}