import { Card, Image } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import i18n from '../../i18n';
import { NewsItem, NewsItemType } from '../../models/NewsItem';
import { ParseMlText } from './MlTextHelper';

interface Props {
    newsItem: NewsItem
}

export const NewsItemItem = (props: Props) => {

    const language = i18n.language

    const renderSubComponent = () => {
        switch(props.newsItem.type) {
            case NewsItemType.Photo: return (_.map(props.newsItem.imageIds, (imageId, index) => {
                                        return <Image key={index} src={`${process.env.REACT_APP_SERVER_URL}/image/${imageId}`} preview={true} className='news-item-image' loading='lazy' />
                                    }))
            case NewsItemType.Youtube: return <iframe 
                                        title='Youtube Video'
                                        width="75%" 
                                        height="380"
                                        style={{ maxWidth: 600 }}
                                        src={`https://youtube.com/embed/${props.newsItem.youTubeCode}`}
                                        frameBorder={0}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                        allowFullScreen={true}
                                        className='orange-border'>
                                    </iframe>
            default: return <div></div>
        }
    }

    return(
        <div className='content-flex bg-lightgray' style={{ paddingTop: '10px'}}>
            <Card
                // key={props.key}
                bordered={false} 
                className='card card-single margin-top-bottom-20'
                title={`${moment(props.newsItem.date).format('DD-MM-YYYY')} : ${ParseMlText(props.newsItem.title)[language]}`} >
                <span style={{color: '#000', fontWeight: 400}}>
                    <div dangerouslySetInnerHTML={{__html: ParseMlText(props.newsItem.description)[language]?.replaceAll('\n', '<br>') }} />
                    <br/>
                    <br/>
                    { renderSubComponent() }
                </span>
            </Card>
        </div>
    )
}