import { Breadcrumb, Button, Col, Row, Image } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PushpinOutlined, PhoneOutlined, PrinterOutlined, MailOutlined } from '@ant-design/icons';
import { CardDouble } from './utilities/CardDouble';
import { Helmet } from 'react-helmet';
// import { useParams } from 'react-router-dom';

export const Contact = () => { 

    const { t } = useTranslation();
    // let { lng } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <React.Fragment>
            <Helmet>
                <title>InnoTrans | {t('common:contact')}</title>
                <meta name="description" content={t('pageDescriptions:contact')} />
                <meta name="keywords" content={t('pageKeywords:contact')} />
                <link rel="canonical" href={`https://innotrans.nl/contact`} />
            </Helmet>
            
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item><h1>{t('common:contact')}</h1></Breadcrumb.Item>
            </Breadcrumb>

            {/* <div className='content-flex' style={{ paddingTop: '25px' }} > */}
                <CardDouble
                    children1={
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d685646.686198379!2d4.789539018969872!3d51.57814146292505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6a747ecea87cd%3A0x6d9737344460967f!2sInnotrans!5e0!3m2!1snl!2snl!4v1539612411720" 
                            width='100%'
                            height='380'
                            frameBorder="0" 
                            style={{ maxWidth: '600px', border: '0px' }} 
                            title='Google Maps location'
                            allowFullScreen={false}></iframe>
                    }
                    children2={
                        <React.Fragment>
                            <div>
                                <Image src='/images/logo_large.jpg' preview={false} loading='lazy' title='Innotrans Logo' />
                            </div>
                            <div className='content-flex' style={{ paddingTop: '10px', paddingBottom: '25px' }} >
                                <div className={'ant-card-meta-description'} style={{ textAlign: 'left', lineHeight: 2, minWidth: '300px'}}>
                                    <Row>
                                        <Col span={5} offset={3}><PushpinOutlined /></Col>
                                        <Col span={16}>Hazeldonk 6267</Col>
                                    </Row>
                                    <Row>
                                        <Col span={5} offset={3}></Col>
                                        <Col span={16}>4836 LG Breda</Col>
                                    </Row>
                                    <Row></Row>
                                    <Row>
                                        <Col span={5} offset={3}><PhoneOutlined /></Col>
                                        <Col span={16}>+31 (0)76 596 9740</Col>
                                    </Row>
                                    <Row>
                                        <Col span={5} offset={3}><PrinterOutlined /></Col>
                                        <Col span={16}>+31 (0)76 596 9741</Col>
                                    </Row>
                                    <Row>
                                        <Col span={5} offset={3}><MailOutlined /></Col>
                                        <Col span={16}><a href='mailto:info@innotrans.nl'>info@innotrans.nl</a></Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col span={5} offset={3} style={{fontSize: '14px', paddingTop: '4px'}}>BTW:</Col>
                                        <Col span={16}>NL806503397B01</Col>
                                    </Row>
                                    <Row>
                                        <Col span={5} offset={3} style={{fontSize: '14px', paddingTop: '4px'}}>EORI:</Col>
                                        <Col span={16}>NL806503397</Col>
                                    </Row>
                                    <Row>
                                        <Col span={5} offset={3} style={{fontSize: '14px', paddingTop: '4px'}}>KvK:</Col>
                                        <Col span={16}>20088034</Col>
                                    </Row>
                                    
                                    <Row style={{ paddingTop: '25px'}}>
                                        <Col span={3} offset={2}>
                                            <Button 
                                                type="default" 
                                                shape="circle" 
                                                icon={<Image src='/images/home/social/linkedin.svg' style={{ maxWidth: '38px'}} preview={false} loading='lazy' title='LinkedIn' />} 
                                                style={{ padding: 0 }}
                                                size='large' 
                                                title='LinkedIn'
                                                onClick={ () => window.location.assign('http://www.linkedin.com') }/>
                                        </Col>
                                        <Col span={3} offset={5}>
                                            <Button 
                                                type="default" 
                                                shape="circle" 
                                                icon={<Image src='/images/home/social/facebook.svg' style={{ maxWidth: '38px' }} preview={false} loading='lazy' title='Facebook' />} 
                                                style={{ padding: 0 }}
                                                size='large' 
                                                title='Facebook'
                                                onClick={ () => window.location.assign('http://www.facebook.com') }/>
                                        </Col>
                                        <Col span={3} offset={5}>
                                            <Button 
                                                type="default" 
                                                shape="circle" 
                                                icon={<Image src='/images/home/social/youtube.svg' style={{ maxWidth: '38px' }} preview={false} loading='lazy' title='YouTube' />} 
                                                style={{ padding: 0 }}
                                                size='large' 
                                                title='YouTube' 
                                                onClick={ () => window.location.assign('http://www.youtube.com') }/>
                                        </Col>
                                    </Row>
                                        
                                </div>
                            </div>
                        </React.Fragment>
                    } 
                />
            {/* </div> */}

        </React.Fragment>
    );
}
