import { Button, Card, Popconfirm, Upload } from "antd";
import React from "react";
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Image } from "../../../models/Image";
import { ImageFromBlob } from "./ImageFromBlob";
import ImgCrop from "antd-img-crop";
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { ImageFromId } from "../../utilities/ImageFromId";

interface Props {
    image?: Image
    onChange(image?: Image): void
}

export const ImageManager = (props: Props) => {

    const customUploadRequest = (options: UploadRequestOption<any>) => {
        const image = { file: options.file } as Image
        props.onChange(image)
    }

    return (
        <React.Fragment>
            { props.image ? 
                <Card
                    style={{ width: '200px', marginRight: '16px', marginBottom: '16px'}}
                    bodyStyle={{ padding: 0}}
                    actions={[
                        
                        <Popconfirm
                            title='Are you sure you want to delete this image?'
                            onCancel={(e) => e?.stopPropagation()}
                            onConfirm={(e) => {
                                e?.stopPropagation()
                                props.onChange(undefined)
                            }}>
                            <Button danger size='small' icon={<DeleteOutlined />} title='Delete Image' onClick={e => e.stopPropagation()} />
                        </Popconfirm>
                    ]}>
                    { props.image ?
                        props.image.imageId ?
                            <ImageFromId imageId={props.image.imageId} width={200} preview={true} />
                            :   
                            <ImageFromBlob blob={props.image.file} />
                        :null
                    }
                </Card>
            : 
                <div style={{width: '200px', height: 208, marginRight: 9, marginBottom: 9 }}>
                    <ImgCrop zoom aspect={1.5}>
                        <Upload 
                            customRequest={customUploadRequest} 
                            showUploadList={false} 
                            listType="picture-card"
                            accept='image/*'
                            style={{ width: 200, height: 208, padding: 0 }}
                            >
                            <PlusOutlined />
                        </Upload>
                    </ImgCrop>
                </div>
            }           
            
        </React.Fragment>
    )
}