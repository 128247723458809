import { Breadcrumb, Card, Menu, Empty, Row, Col, Button } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import i18n from '../i18n';
import SubMenu from 'antd/lib/menu/SubMenu';
import { PartCategory } from '../models/PartCategory';
import { Part } from '../models/Part';
import { MultiFetcher } from './utilities/MultiFetcher';
import { PageLoader } from './utilities/PageLoader';
import * as _ from 'lodash';
import { ParseMlText } from './utilities/MlTextHelper';
import Meta from 'antd/lib/card/Meta';
import { ImageFromId } from './utilities/ImageFromId';
import { CloseOutlined } from '@ant-design/icons';

interface State {
    categories: PartCategory[]
    selectedCategoryId?: string
    parts: Part[],
    openKeys: string[]
}

export const Parts = () => { 

    const { t } = useTranslation();
    // let { lng } = useParams();
    const history = useHistory();
    const language = i18n.language

    const [state, setState] = useState<State>({
        categories: [],
        selectedCategoryId: undefined,
        parts: [], 
        openKeys: []
      });

    const {fetchMultiple: fetchCategories, isLoading: isLoadingCategories} = MultiFetcher('partcategory');   
    const {fetchMultiple: fetchParts, isLoading: isLoadingParts} = MultiFetcher('part');   

    const fetchCategoriesAsync = useCallback(async () => {
        const categories = await fetchCategories({});
        setState(prevState => ({ ...prevState, categories }));
        //console.log(`data: ${JSON.stringify(categories)}`); 
    }, [fetchCategories]);

    const fetchPartsAsync = useCallback(async () => {
        const queryParams = state.selectedCategoryId ? `categoryId=${state.selectedCategoryId}` : undefined;
        const parts = await fetchParts({queryParams});
        setState(prevState => ({ ...prevState, parts: parts.filter(part => part.visible === true) }));
        //console.log(`data: ${JSON.stringify(parts)}`); 
    }, [fetchParts, state.selectedCategoryId]);

    
    useEffect(() => {
        window.scrollTo(0, 0)
        fetchCategoriesAsync()
      }, [fetchCategoriesAsync])

    useEffect(() => {
        fetchPartsAsync()
    }, [fetchPartsAsync])

    const onOpenChange = (keys: any[]) => {
        const latestOpenKey = keys.find(key => state.openKeys.indexOf(key) === -1);
        if (state.categories?.filter(cat => cat.parentId == null).map(cat => cat.id).indexOf(latestOpenKey) === -1) {
            setState(prevState => ({ ...prevState, openKeys: keys }));
        } else {
            setState(prevState => ({ ...prevState, openKeys: latestOpenKey ? [latestOpenKey] : [] }));
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>InnoTrans | {t('common:parts')}</title>
                <meta name="description" content={t('pageDescriptions:parts')} />
                <meta name="keywords" content={t('pageKeywords:parts')} />
                <meta httpEquiv="content-language" content={i18n.language?.substr(0,2)} />
                {/* <link rel="canonical" href={`https://innotrans.nl${lng ? `/${lng}` : ''}/parts`} /> */}
            </Helmet>
            
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item><h1>{t('common:parts')}</h1></Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px'}} className={'ant-card-head-title'}>{t('common:parts')}</div>

            <Row>
                <Col 
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 9, offset: 0 }}
                    md={{ span: 6, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 4, offset: 1 }}>
                    <PageLoader loading={isLoadingCategories}>
                        <Menu 
                            theme='light' 
                            mode='inline' 
                            className='catMenu'
                            activeKey={state.selectedCategoryId}
                            selectedKeys={state.selectedCategoryId ? [state.selectedCategoryId] : []}
                            openKeys={state.openKeys} onOpenChange={onOpenChange}>
                                { _.map(state.categories.filter(cat => cat.parentId == null), (rootCat) => {
                                    const subItems = state.categories.filter(cat => cat.parentId === rootCat.id);
                                    
                                    if (subItems.length === 0){
                                        return <Menu.Item 
                                                    key={rootCat.id}
                                                    style={{ color: 'white'}}
                                                    className='bg-blue'
                                                    onClick={(item) => setState(prevState => ({ ...prevState, selectedCategoryId: item.key.toString() }))}>
                                                        {ParseMlText(rootCat.name)[language]}
                                                </Menu.Item>
                                    } else {
                                        return (
                                            <SubMenu 
                                                key={rootCat.id} 
                                                title={ParseMlText(rootCat.name)[language]} 
                                                style={{ color: 'white'}}
                                                className='bg-blue'
                                                // onTitleClick={(item) => setState(prevState => ({ ...prevState, selectedCategoryId: item.key.toString() }))}
                                                >
                                                { _.map(subItems, (subCat) => {
                                                    return <Menu.Item 
                                                            key={subCat.id}    
                                                            onClick={(item) => setState(prevState => ({ ...prevState, selectedCategoryId: item.key.toString() }))}>
                                                                {ParseMlText(subCat.name)[language]}
                                                            </Menu.Item>
                                                })}
                                            </SubMenu>
                                        )
                                    }
                                })}
                        </Menu>
                        <Button type="default" icon={<CloseOutlined />} disabled={state.selectedCategoryId === undefined} onClick={() => {
                            setState(prevState => ({ ...prevState, openKeys: [], selectedCategoryId: undefined }))
                        }}>Clear selection</Button>
                    </PageLoader>
                </Col>
                <Col 
                    xs={{ span: 24, offset: 0 }}    
                    sm={{ span: 14, offset: 1 }}
                    md={{ span: 15, offset: 1 }}
                    lg={{ span: 16, offset: 1 }}
                    xl={{ span: 17, offset: 1 }}>
                    <PageLoader loading={isLoadingParts}>
                        <div className='content-flex' style={{ paddingBottom: 25, justifyContent: 'left' }}>
                            { (state.parts.length === 0)  ? 
                                <Empty />
                            : _.map(state.parts, (part, index) => {
                                return (
                                <Card
                                    key={index}
                                    hoverable
                                    style={{ width: 240, marginRight: 20, marginBottom: 20 }}
                                    cover={<ImageFromId imageId={part.imageIds[0]} preview={false} />}
                                    onClick={ () => history.push(`/partdetails/${part.id}`) }
                                    >
                                    <Meta title={ParseMlText(part.name)[language]} description={`€ ${Intl.NumberFormat('nl-NL').format(part.price)}`} />
                                </Card>)
                            })}
                        </div>
                    
                    </PageLoader>
                </Col>
            </Row>
        </React.Fragment>
    );
}
