import { Button, Result } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const Status403 = () => {
  const history = useHistory();
  return (
    <React.Fragment>
      <Result
        status={403}
        title='Forbidden'
        subTitle='Sorry, you are not authorized to access this page.'
        extra={<Button type='primary' onClick={() => history.push('/')} >Back Home</Button>}
      />
    </React.Fragment>
  );
};
