import React from 'react';
import { Card } from 'antd';
import Meta from 'antd/lib/card/Meta';

interface Props {
    title: string;
    children: React.ReactNode;
    metaAvatar?: React.ReactNode;
}

export const CardSingle = (props: Props) => {
    return (
        <React.Fragment>
            <div className='content-flex'>
                <Card
                    bordered={false} 
                    className='card card-single'
                    title={props.title}>
                    <Meta avatar={props.metaAvatar} />
                    <span style={{color: '#000', fontWeight: 400}}>
                        {props.children}
                    </span>
                </Card>
            </div>
        </React.Fragment>
    );
}