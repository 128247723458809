import React from "react";
import { Image } from "antd";

interface Props {
    imageId: string
    width?: number
    preview: boolean
    style?: React.CSSProperties
}

export const ImageFromId = (props: Props) => {
   return (
    <Image 
        src={`${process.env.REACT_APP_SERVER_URL}/image/${props.imageId}`} 
        width={props.width} 
        preview={props.preview}
        fallback='/images/default.png' 
        style={Object.assign({ cursor: 'pointer' }, props.style)}  
        loading='lazy' />
   )
}
