import { Breadcrumb, Space, Button, Descriptions } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import i18n from '../i18n';
import { MultiFetcher } from './utilities/MultiFetcher';
import { PageLoader } from './utilities/PageLoader';
import { ArrowLeftOutlined  } from '@ant-design/icons';
import { Job } from '../models/Job';
import moment from 'moment';

interface State {
    job?: Job; 
}

export const JobDetails = () => { 

    const { t } = useTranslation();
    const { id } = useParams();
    const history = useHistory();

    const [state, setState] = useState<State>({
        job: undefined
    });

    const {fetchSingle: fetchJob, isLoading} = MultiFetcher('job');   

    const fetchJobAsync = useCallback(async () => {
        const job = await fetchJob({id});
        setState(prevState => ({ ...prevState, job }));
        //console.log(`data: ${JSON.stringify(job)}`); 
    }, [fetchJob, id]);

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchJobAsync()
      }, [fetchJobAsync])

    return (
        <React.Fragment>
            <Helmet>
                <title>InnoTrans | {t('common:jobs')}</title>
                <meta name="description" content={t('pageDescriptions:jobs')} />
                <meta name="keywords" content={t('pageKeywords:jobs')} />
                <meta httpEquiv="content-language" content={i18n.language?.substr(0,2)} />
                {/* <link rel="canonical" href={`https://innotrans.nl${lng ? `/${lng}` : ''}/stocks`} /> */}
            </Helmet>
            
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item><h1>{t('common:jobs')}</h1></Breadcrumb.Item>
            </Breadcrumb>

            <PageLoader loading={isLoading}>
                <div style={{ width: '100%', textAlign: 'center'}} className={'ant-card-head-title'}>{ state.job?.title}</div>
                <div style={{ padding: 25 }}>
                    <Descriptions bordered column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }} className="th-width-10perc">
                        <Descriptions.Item label={t('common:date')} style={{ verticalAlign: 'top' }}>{moment(state.job?.date).format('DD-MM-YYYY')}</Descriptions.Item>
                        <Descriptions.Item label={t('common:placeOfWork')} style={{ verticalAlign: 'top' }}>{state.job?.placeOfWork ?? '-'}</Descriptions.Item>
                        <Descriptions.Item label={t('common:jobDescription')} span={2} style={{ verticalAlign: 'top' }}>
                            <div dangerouslySetInnerHTML={{__html: state.job ? state.job.description?.replaceAll('\n', '<br>'): '' }} />
                        </Descriptions.Item>
                        <Descriptions.Item label={t('common:requirements')} span={2} style={{ verticalAlign: 'top' }}>
                            <div dangerouslySetInnerHTML={{__html: state.job ? state.job.requirements?.replaceAll('\n', '<br>'): '' }} />
                        </Descriptions.Item>
                        <Descriptions.Item label={t('common:terms')} span={2} style={{ verticalAlign: 'top' }}>
                            <div dangerouslySetInnerHTML={{__html: state.job ? state.job.terms?.replaceAll('\n', '<br>'): '' }} />
                        </Descriptions.Item>
                        <Descriptions.Item label={t('common:numberOfHours')} style={{ verticalAlign: 'top' }}>{state.job?.numberOfHours ?? '-'}</Descriptions.Item>
                        <Descriptions.Item label={t('common:workThinkLevel')} style={{ verticalAlign: 'top' }}>{state.job?.workThinkLevel ?? '-'}</Descriptions.Item>
                        <Descriptions.Item label={t('common:fieldOfWork')} style={{ verticalAlign: 'top' }}>{state.job?.fieldOfWork ?? '-'}</Descriptions.Item>
                    </Descriptions>
                    <Space style={{paddingTop: 25}}>
                        <Button type='default' icon={<ArrowLeftOutlined />} onClick={() => history.push('/Jobs')}>{t('common:back')}</Button>
                    </Space>
                </div>
            </PageLoader>
        </React.Fragment>
    );
}
