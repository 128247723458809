import React from "react"
import { Button, Popconfirm } from "antd"
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";

interface AddButtonProps {
    url: string
}

export const AddButton = (props: AddButtonProps) => {
    const history = useHistory()
    
    return (
        <Button type='primary' icon={<PlusOutlined/>} onClick={() => history.push(props.url)} >Add</Button>
    )
}


interface EditButtonProps {
    url: string
}

export const EditButton = (props: EditButtonProps) => {
    const history = useHistory()
    
    return (
        <Button size='small' icon={<EditOutlined />} title='Edit' onClick={() => history.push(props.url)} />
    )
}

interface DeleteButtonProps {
    id: string
    onDelete(id: string): void
}

export const DeleteButton = (props: DeleteButtonProps) => {
    return (
        <Popconfirm
            title='Are you sure you want to delete this item?'
            onCancel={(e) => e?.stopPropagation()}
            onConfirm={(e) => {
                e?.stopPropagation();
                props.onDelete(props.id);
            }}>
            <Button size='small' danger icon={<DeleteOutlined />} title='Delete item' onClick={e => e.stopPropagation()} />
        </Popconfirm>
    )
}