import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
 import LanguageDetector from "i18next-browser-languagedetector";
import translations_nl from './locales/nl.json';
import translations_en from './locales/en.json';
import translations_de from './locales/de.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    supportedLngs: [ 'en', 'nl', 'de'],
    nonExplicitSupportedLngs: true,
    lowerCaseLng: true,
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    detection: {
      caches: ['cookie'],
      cookieMinutes: 525600,
      cookieSameSite: 'strict',
      order: ['path', 'cookie', 'navigator', 'htmlTag', 'querystring'],
      lookupFromPathIndex: 0,
      lookupCookie: 'next-i18next',
      checkWhitelist: true,
      debug: true
    },
  }
);

i18n.addResourceBundle('nl', 'common', translations_nl.common);
i18n.addResourceBundle('en', 'common', translations_en.common);
i18n.addResourceBundle('de', 'common', translations_de.common);

i18n.addResourceBundle('nl', 'pageDescriptions', translations_nl.pageDescriptions);
i18n.addResourceBundle('en', 'pageDescriptions', translations_en.pageDescriptions);
i18n.addResourceBundle('de', 'pageDescriptions', translations_de.pageDescriptions);

i18n.addResourceBundle('nl', 'pageKeywords', translations_nl.pageKeywords);
i18n.addResourceBundle('en', 'pageKeywords', translations_en.pageKeywords);
i18n.addResourceBundle('de', 'pageKeywords', translations_de.pageKeywords);

i18n.addResourceBundle('nl', 'home', translations_nl.home);
i18n.addResourceBundle('en', 'home', translations_en.home);
i18n.addResourceBundle('de', 'home', translations_de.home);

i18n.addResourceBundle('nl', 'wheelchairbus', translations_nl.wheelchairbus);
i18n.addResourceBundle('en', 'wheelchairbus', translations_en.wheelchairbus);
i18n.addResourceBundle('de', 'wheelchairbus', translations_de.wheelchairbus);

i18n.addResourceBundle('nl', 'wheelchaircar', translations_nl.wheelchaircar);
i18n.addResourceBundle('en', 'wheelchaircar', translations_en.wheelchaircar);
i18n.addResourceBundle('de', 'wheelchaircar', translations_de.wheelchaircar);

i18n.addResourceBundle('nl', 'electrictransport', translations_nl.electrictransport);
i18n.addResourceBundle('en', 'electrictransport', translations_en.electrictransport);
i18n.addResourceBundle('de', 'electrictransport', translations_de.electrictransport);

i18n.addResourceBundle('nl', 'lowentry', translations_nl.lowentry);
i18n.addResourceBundle('en', 'lowentry', translations_en.lowentry);
i18n.addResourceBundle('de', 'lowentry', translations_de.lowentry);

export default i18n;
