import { Dictionary } from "lodash"

export const ParseMlText = (value: (string | undefined)) : Dictionary<string> => {
    let result : Dictionary<string> = {}
        
    if (value)
    {
        try {
            result = JSON.parse(value) as Dictionary<string>
        } catch {}
    }

    return result;
}

export const StringifyMlText = (en: string, nl: string, de: string) : string => {
    const result : Dictionary<string> = {
        "en": en,
        "nl": nl,
        "de": de
    }
   
    return JSON.stringify(result)
}
