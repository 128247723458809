import { Breadcrumb, Space, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { MultiFetcher } from '../../utilities/MultiFetcher';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { EditButton } from '../_common/CustomButtons';

interface State {
    carousels: any;
  }

export const CarouselList = withAuthenticationRequired(() => {    
    const [state, setState] = useState<State>({
        carousels: []
      });

    const {fetchMultiple: fetchCarousels, isLoading} = MultiFetcher('carousel');   

    const fetchData = useCallback(async () => {
        const carousels = await fetchCarousels({disableCache: true});
        setState(prevState => ({ ...prevState, carousels }));

        //console.log(`data: ${JSON.stringify(Carousels)}`); 
    }, [fetchCarousels]);

    useEffect(() => {
        window.scrollTo(0, 0);

        fetchData();

    }, [fetchData]);

    return (
        <React.Fragment>
            
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item>Admin</Breadcrumb.Item>
                <Breadcrumb.Item>Carousels</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ width: '80%', margin: 'auto', marginBottom: '25px'}}>

                <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px'}} className={'ant-card-head-title'}>Carousels</div>

                <Table 
                    loading={isLoading}
                    rowKey={'id'}
                    columns={[
                        // {
                        //     title: 'Id',
                        //     dataIndex: 'id',
                        //     key: 'id',
                        // },
                        {
                            title: 'Name',
                            dataIndex: 'name',
                            key: 'name',
                        },
                        {
                            title: '# Photos',
                            dataIndex: 'imageIds',
                            key: 'imageIds',
                            render: (imageIds: string[]) => <span>{imageIds?.length ?? 0}</span>
                        },
                        {
                            key: 'actions',
                            align: 'right',
                            render: (record: any) => 
                                <Space>
                                    <EditButton url={`/Admin/Carousel/${record.id}`} />
                                </Space>
                        }
                            
                    ]} 
                    dataSource={state.carousels} />

            </div>

        </React.Fragment>
    );
})