import { Breadcrumb, Row, Col, Space, Button, Descriptions } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import i18n from '../i18n';
import { Part } from '../models/Part';
import { MultiFetcher } from './utilities/MultiFetcher';
import { PageLoader } from './utilities/PageLoader';
import * as _ from 'lodash';
import { ParseMlText } from './utilities/MlTextHelper';
import { ImageFromId } from './utilities/ImageFromId';
import AliceCarousel from 'react-alice-carousel'
import { ArrowLeftOutlined  } from '@ant-design/icons';

interface State {
    part?: Part; 
}

export const PartDetails = () => { 

    const { t } = useTranslation();
    const { id } = useParams();
    const history = useHistory();
    const language = i18n.language

    const [state, setState] = useState<State>({
        part: undefined
    });

    const {fetchSingle: fetchPart, isLoading} = MultiFetcher('part');   

    const fetchPartAsync = useCallback(async () => {
        const part = await fetchPart({id});
        setState(prevState => ({ ...prevState, part }));
        //console.log(`data: ${JSON.stringify(parts)}`); 
    }, [fetchPart, id]);

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchPartAsync()
      }, [fetchPartAsync])

      const getCategoryName = (part: Part, language: string) => {
        return part.parentCategoryName ? `${ParseMlText(part.parentCategoryName)[language]} / ${ParseMlText(part.categoryName)[language]}` : ParseMlText(part.categoryName)[language]
      }

    return (
        <React.Fragment>
            <Helmet>
                <title>InnoTrans | {t('common:parts')}</title>
                <meta name="description" content={t('pageDescriptions:parts')} />
                <meta name="keywords" content={t('pageKeywords:parts')} />
                <meta httpEquiv="content-language" content={i18n.language?.substr(0,2)} />
                {/* <link rel="canonical" href={`https://innotrans.nl${lng ? `/${lng}` : ''}/parts`} /> */}
            </Helmet>
            
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item><h1>{t('common:parts')}</h1></Breadcrumb.Item>
            </Breadcrumb>

            <PageLoader loading={isLoading}>
                <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px'}} className={'ant-card-head-title'}>{ParseMlText(state.part?.name)[language]}</div>
                <Row>
                    <Col 
                        xs={{ span: 22, offset: 1 }}
                        md={{ span: 9, offset: 1 }}
                        lg={{ span: 8, offset: 1 }}
                        xl={{ span: 7, offset: 1 }}>
                         <AliceCarousel 
                            autoPlay={true}
                            duration={2000}
                            responsive={{ 0: { items: 1 } }}
                            buttonsDisabled={false}
                            dotsDisabled={false}
                            mouseTrackingEnabled={false}
                            autoHeight={false}
                            stagePadding={{
                                paddingLeft: 0,
                                paddingRight: 0
                            }}>
                                { _.map(state.part?.imageIds, (imageId, index) => {
                                    return  <ImageFromId key={index} imageId={imageId} preview={true} style={{ maxHeight: 800 }} />
                                })}
                        </AliceCarousel>
                    </Col>
                    <Col 
                        xs={{ span: 22, offset: 1 }}
                        md={{ span: 12, offset: 1 }}
                        lg={{ span: 13, offset: 1 }}
                        xl={{ span: 14, offset: 1 }}>
                            <Descriptions column={1} bordered>
                                <Descriptions.Item label={t('common:category')} style={{ verticalAlign: 'top' }}>{state.part ? getCategoryName(state.part, language) : ''}</Descriptions.Item>
                                <Descriptions.Item label={t('common:description')} style={{ verticalAlign: 'top' }}>
                                    <div dangerouslySetInnerHTML={{__html: state.part ? ParseMlText(state.part.description)[language]?.replaceAll('\n', '<br>'): '' }} />
                                </Descriptions.Item>
                                <Descriptions.Item label={t('common:price')} style={{ verticalAlign: 'top' }}>{state.part ? `€ ${Intl.NumberFormat('nl-NL').format(state.part.price)}` : ''}</Descriptions.Item>
                            </Descriptions>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 22, offset: 1 }} style={{ marginTop: 25, textAlign: 'left'}}>
                        <Space>
                            <Button type='default' icon={<ArrowLeftOutlined />} onClick={() => history.push('/Parts')}>{t('common:back')}</Button>
                        </Space>
                    </Col>
                </Row>
            </PageLoader>
        </React.Fragment>
    );
}
