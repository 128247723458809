import { useAuth0 } from '@auth0/auth0-react';
import { notification } from 'antd';
import _ from 'lodash';
import { useCallback, useState } from 'react';

interface State {
    isLoading: boolean;
  }

export const MultiFetcher = (endpoint: string) => {
    const basePath = `${process.env.REACT_APP_SERVER_URL}/${endpoint}/`;
    const { getAccessTokenSilently } = useAuth0();

    const [state, setState] = useState<State>({
        isLoading: false
      });

    const showError = (method: string, error: string) => {
        notification['error']({
          message: error,
          description: `An error occurred whilst executing ${method}.`
        });
    };

    const handleError = useCallback((response: Response) =>  {
        if (!response.ok) {
            throw Error(response.status.toString());
        }
        return response;
    }, []);

    interface CreateRequestOptionsParams {
        method: string
        useToken: boolean
        body?: any
        specifyContentType: boolean,
        disableCache?: boolean
    }

    const createRequestOptions = useCallback(async (params: CreateRequestOptionsParams) : Promise<RequestInit> => {
        var headers: {[k: string]: string} = {}

        if (params.specifyContentType === true) {
            headers['Content-Type'] = 'application/json'
        }

        if (params.useToken === true) {
            const token = await getAccessTokenSilently()
            headers['Authorization'] = `Bearer ${token}`
        }

        if (params.disableCache === true) {
            headers['Cache-Control'] = 'private, no-store, max-age=0'
        }

       return  {
            method: params.method,
            headers: headers,
            body: params.body
        };
      
    }, [getAccessTokenSilently])

    interface FetchMultipleParams {
        queryParams?: string
        disableCache?: boolean
    }

    const fetchMultiple = useCallback(async (params: FetchMultipleParams): Promise<[any]> => {

        setState(prevState => ({ ...prevState, isLoading: true }));

        const requestOptions = await createRequestOptions({method: 'GET', useToken: false, specifyContentType: true, disableCache: params.disableCache});

        const url = params.queryParams ? basePath + '?' + params.queryParams : basePath;

        const response = await fetch(url, requestOptions)
            .then(handleError)
            .then(response => response.json())
            .catch(error => {
                showError('fetchMultiple', error.toString());
            })
            .finally(() => {
                setState(prevState => ({ ...prevState, isLoading: false }));
            });

        return response || null;

    }, [basePath, createRequestOptions, handleError]);

    interface FetchSingleParams {
        id: string
        disableCache?: boolean
    }

    const fetchSingle = useCallback(async (params: FetchSingleParams): Promise<any> => {

        setState(prevState => ({ ...prevState, isLoading: true }));

        const requestOptions = await createRequestOptions({method: 'GET', useToken: false, specifyContentType: true, disableCache: params.disableCache});
        
        const response = await fetch(basePath + `${params.id}`, requestOptions)
            .then(handleError)
            .then(response => response.json())
            .catch(error => {
                showError('fetchSingle', error.toString());
            })
            .finally(() => {
                setState(prevState => ({ ...prevState, isLoading: false }));
            });

        return response || null;

    }, [basePath, createRequestOptions, handleError])

    const postSingle = useCallback(async (data: any): Promise<any> => {

        setState(prevState => ({ ...prevState, isLoading: true }));

        const requestOptions = await createRequestOptions({method: 'POST', useToken: true, body: JSON.stringify(data), specifyContentType: true});

        const response = await fetch(basePath, requestOptions)
            .then(handleError)
            .then(response => response.json())
            .catch(error => {
                showError('postSingle', error.toString());
            })
            .finally(() => {
                setState(prevState => ({ ...prevState, isLoading: false }));
            });

        return response || null;

    }, [basePath, createRequestOptions, handleError])

    const deleteSingle = useCallback(async (id: string): Promise<any> => {

        setState(prevState => ({ ...prevState, isLoading: true }));

        const requestOptions = await createRequestOptions({method: 'DELETE', useToken: true, specifyContentType: true});

        const response = await fetch(basePath + `${id}`, requestOptions)
            .then(handleError)
            .then(response => response)
            .catch(error => {
                showError('deleteSingle', error.toString());
            })
            .finally(() => {
                setState(prevState => ({ ...prevState, isLoading: false }));
            });

        return response || null;

    }, [basePath, createRequestOptions, handleError])

    const postFile = useCallback(async (data: any, querystringParams?: { [key: string]: string }, id?: string): Promise<any> => {

        setState(prevState => ({ ...prevState, isLoading: true }));

        const formData = new FormData();
        formData.append('file', data);
    
        const requestOptions = await createRequestOptions({method: 'POST', useToken: true, body: formData, specifyContentType: false});

        let url = basePath + (id ? id : '')
        if (querystringParams) {
            const queryParams = _.map(querystringParams, (value, prop) => `${prop}=${value}`).join('&');
            url += '?' + queryParams;
            url = url.replace(/(https?:\/\/)|(\/)+/g, '$1$2');
        }

        const response = await fetch(url, requestOptions)
            .then(handleError)
            .then(response => response.json())
            .catch(error => {
                showError('postFile', error.toString());
            })
            .finally(() => {
                setState(prevState => ({ ...prevState, isLoading: false }));
            });

        return response || null;

    }, [basePath, createRequestOptions, handleError])

    return {
        isLoading: state.isLoading,
        fetchMultiple,
        fetchSingle,
        postSingle,
        deleteSingle,
        postFile
      };
}