import { Breadcrumb, Button, Col, Form, Input, Row, Space } from 'antd';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SaveOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { MultiFetcher } from '../../utilities/MultiFetcher';
import { useState } from 'react';
import * as _ from 'lodash';
import { Image } from '../../../models/Image';
import { Carousel } from '../../../models/Carousel';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { PageLoader } from '../../utilities/PageLoader';
import { ImagesManager } from '../_common/ImagesManager';
import { useTranslation } from 'react-i18next';

interface State {
    images: Image[]; 
}

export const CarouselEditor = withAuthenticationRequired(() => { 
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const history = useHistory();

    const [state, setState] = useState<State>({
        images: []
    });
   
    const {fetchSingle: fetchCarousel, postSingle: postCarousel, isLoading: isLoadingCarousel} = MultiFetcher('carousel');   
    const {postFile: uploadImage, isLoading: isLoadingImage} = MultiFetcher('image');   

    useEffect(() => {
        window.scrollTo(0, 0)      
    
        if (id)
        {
            const fetchData = async () => {
                const carousel = await fetchCarousel({id, disableCache: true}) as Carousel;

                form.setFieldsValue({
                    id: carousel.id,
                    name: carousel.name,
                    order: carousel.order
                });

                //console.log(`imageIds: ${Carousel.imageIds}`)

                let images : Image[] = []

                _.map(carousel.imageIds, imageId => {
                    images.push({
                        imageId: imageId
                    } as Image)
                })

                //console.log(`photos: ${JSON.stringify(photos)}`)
                setState(prevState => ({ ...prevState, images }));
            }
            fetchData();
        }
        
    }, [fetchCarousel, form, id]);
     

    const onImagesChanged = ((images: Image[]) => {
        setState(prevState => ({ ...prevState, images }));
    })

    const Submit = async (data: Carousel) => {
        const images = state.images;

        Promise.all(_.map(images.filter(image => image.imageId == null), async (image) => {
            image.imageId = await uploadImage(image.file)
        })).then(() => {
            data.imageIds = images.map(image => image.imageId) as string[]

            //console.log(`data.imageIds: ${JSON.stringify(data.imageIds)}`)

            postCarousel(data).then(() => {
                history.push('/admin/carousels') 
            });
        })
    }

    return (
        <React.Fragment>
           
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item>Admin</Breadcrumb.Item>
                <Breadcrumb.Item>Carousels</Breadcrumb.Item>
                <Breadcrumb.Item>{form.getFieldValue('name')}</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ width: '90%', margin: 'auto', marginBottom: '25px'}}>

                <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px'}} className={'ant-card-head-title'}>Carousel Details</div>

                <PageLoader loading={isLoadingCarousel || isLoadingImage} subTitle={'Loading data...'}>
                    <Form
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 14 }}
                        layout="horizontal"
                        form={form}
                        onFinish={Submit}
                    >
                        <Form.Item name='id' hidden={true}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='name' label="Name" >
                            <Input readOnly />
                        </Form.Item>

                        <Form.Item name='order' hidden={true}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='images' label="Images">
                            <ImagesManager images={state.images} max={10} onChange={onImagesChanged} />
                        </Form.Item>
                                
                    </Form>

                    <Row>
                        <Col span={14} offset={6} style={{ textAlign: 'right'}}>
                            <Space>
                                <Button type='default' icon={<ArrowLeftOutlined />} onClick={() => history.push('/admin/carousels')}>{t('common:back')}</Button>
                                <Button type='primary' icon={<SaveOutlined/>} onClick={() => form.submit()}>Save</Button>
                            </Space>
                        </Col>
                    </Row>
                </PageLoader>
                    
            </div>

        </React.Fragment>
    );
})