import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { PageLoader } from './Components/utilities/PageLoader';
import ReactGA from 'react-ga4';
import { createBrowserHistory } from 'history';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

const history = createBrowserHistory()

ReactGA.initialize('UA-178758177-1');

ReactDOM.render(
  // <React.StrictMode>'
   <Suspense fallback={<PageLoader loading={true} subTitle={'Loading...'} />}>
      <I18nextProvider i18n={i18n}>
        <Router history={history} >
          <Auth0Provider
            domain={`${domain}`}
            clientId={`${clientId}`}
            redirectUri={window.location.origin}
            responseType='token id_token'
            audience={`${audience}`}
            scope='openid profile manage:newsitems manage:documents'
          >
            <App />
          </Auth0Provider>
        </Router>
      </I18nextProvider>
    </Suspense>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
