import { Breadcrumb } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { NewsItemItem } from './utilities/NewsItemItem';
import { MultiFetcher } from './utilities/MultiFetcher';
import * as _ from 'lodash';
import { PageLoader } from './utilities/PageLoader';
import { NewsItem } from '../models/NewsItem';
import i18n from '../i18n';
// import { useParams } from 'react-router-dom';

interface State {
    newsItems: NewsItem[];
}

export const News = () => { 
    const { t } = useTranslation();
    // let { lng } = useParams();

    const [state, setState] = useState<State>({
        newsItems: []
      });

    const {fetchMultiple: fetchNewsItems, isLoading} = MultiFetcher('newsitem');   

    const fetchData = useCallback(async () => {
        const newsItems = await fetchNewsItems({});
        setState(prevState => ({ ...prevState, newsItems }));
        //console.log(`data: ${JSON.stringify(newsItems)}`); 
    }, [fetchNewsItems]);

    useEffect(() => {
        window.scrollTo(0, 0)

        fetchData();
    }, [fetchData])

    return (
        <React.Fragment>
            <Helmet>
                <title>InnoTrans | {t('common:news')}</title>
                <meta name="description" content={t('pageDescriptions:news')} />
                <meta name="keywords" content={t('pageKeywords:news')} />
                <meta httpEquiv="content-language" content={i18n.language?.substr(0,2)} />
                {/* <link rel="canonical" href={`https://innotrans.nl${lng ? `/${lng}` : ''}/news`} /> */}
            </Helmet>

            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item><h1>{t('common:news')}</h1></Breadcrumb.Item>
            </Breadcrumb>

            <PageLoader loading={isLoading} subTitle={'Loading data...'}>
                {_.map(state.newsItems, (item, index) => {
                    return <NewsItemItem key={index} newsItem={item} />
                })}
            </PageLoader>

        </React.Fragment>
    );
}
