import { Breadcrumb, Button, Checkbox, Col, Form, Input, InputNumber, Row, Select, Space } from 'antd';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SaveOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { MultiFetcher } from '../../utilities/MultiFetcher';
import { useState } from 'react';
import * as _ from 'lodash';
import { Image } from '../../../models/Image';
import { Stock } from '../../../models/Stock';
import { ParseMlText, StringifyMlText } from '../../utilities/MlTextHelper';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { PageLoader } from '../../utilities/PageLoader';
import { ImagesManager } from '../_common/ImagesManager';
import { StockCategory } from '../../../models/StockCategory';
import { StockCategoriesDropDown } from '../_common/StockCategoriesDropDown';
import { useTranslation } from 'react-i18next';
import { HtmlEditor } from '../_common/HtmlEditor';
import BraftEditor from 'braft-editor';

interface State {
    images: Image[]; 
    categories: StockCategory[];
}

export const StockEditor = withAuthenticationRequired(() => { 
    const { id } = useParams<{ id: string }>()
    const { t } = useTranslation();
    const [ form ] = Form.useForm();
    const history = useHistory();

    const [state, setState] = useState<State>({
        images: [],
        categories: []
    });
   
    const {fetchSingle: fetchStock, postSingle: postStock, isLoading: isLoadingStock} = MultiFetcher('stock');   
    const {fetchMultiple: fetchCategories, isLoading: isLoadingCategories} = MultiFetcher('stockCategory');   
    const {postFile: uploadImage, isLoading: isLoadingImage} = MultiFetcher('image');   

    useEffect(() => {
        window.scrollTo(0, 0)      
    
        if (id)
        {
            const fetchData = async () => {
                const stock = await fetchStock({id, disableCache: true}) as Stock;

                //console.log(`data: ${JSON.stringify(Stock)}`);
                let name = ParseMlText(stock.name)
                let description = ParseMlText(stock.description)
                let lift = ParseMlText(stock.lift)
                let entry = ParseMlText(stock.entry)
                
                form.setFieldsValue({
                    id: stock.id,
                    categoryId: stock.categoryId,
                    name_en: name['en'],
                    name_nl: name['nl'],
                    name_de: name['de'],
                    description_en: BraftEditor.createEditorState(description['en']),
                    description_nl: BraftEditor.createEditorState(description['nl']),
                    description_de: BraftEditor.createEditorState(description['de']),
                    yearManufactured: stock.yearManufactured,
                    kilometers: stock.kilometers,
                    wheelbase: stock.wheelbase,
                    weight: stock.weight,
                    transmissionType: stock.transmissionType,
                    numberOfWheelchairs: stock.numberOfWheelchairs,
                    lift_en: BraftEditor.createEditorState(lift['en']),
                    lift_nl: BraftEditor.createEditorState(lift['nl']),
                    lift_de: BraftEditor.createEditorState(lift['de']),
                    entry_en: BraftEditor.createEditorState(entry['en']),
                    entry_nl: BraftEditor.createEditorState(entry['nl']),
                    entry_de: BraftEditor.createEditorState(entry['de']),
                    price: stock.price,
                    priceType: stock.priceType,
                    visible: stock.visible
                });

                //console.log(`imageIds: ${Stock.imageIds}`)

                let images : Image[] = []

                _.map(stock.imageIds, imageId => {
                    images.push({
                        imageId: imageId
                    } as Image)
                })

                //console.log(`images: ${JSON.stringify(images)}`)

                setState(prevState => ({ ...prevState, images }));
            }

            fetchData();
        }
        else
        {
            form.setFieldsValue({ visible: true });
        }
        
        const fetchAllCategories = async() => {
            const categories = await fetchCategories({disableCache:true}) as StockCategory[];
            setState(prevState => ({ ...prevState, categories }));
            //console.log(`categories: ${JSON.stringify(categories)}`)
        }

        fetchAllCategories();
        
    }, [fetchCategories, fetchStock, form, id]);
  
    const onImagesChanged = ((images: Image[]) => {
        setState(prevState => ({ ...prevState, images }));
    })

    const submitStock = async (data: Stock) => {
        data.name = StringifyMlText(
            form.getFieldValue('name_en'), 
            form.getFieldValue('name_nl'),
            form.getFieldValue('name_de')
        )
            
        data.description = StringifyMlText(
            form.getFieldValue('description_en'), 
            form.getFieldValue('description_nl'),
            form.getFieldValue('description_de')
        )

        data.lift = StringifyMlText(
            form.getFieldValue('lift_en'), 
            form.getFieldValue('lift_nl'),
            form.getFieldValue('lift_de')
        )

        data.entry = StringifyMlText(
            form.getFieldValue('entry_en'), 
            form.getFieldValue('entry_nl'),
            form.getFieldValue('entry_de')
        )

        const transmissionType = form.getFieldValue('transmissionType')
        data.transmissionType = transmissionType ? transmissionType : undefined;
        //console.log(`transmissionType: ${transmissionType}`)

        const priceType = form.getFieldValue('priceType')
        data.priceType = priceType ? priceType : undefined;
        //console.log(`priceType: ${priceType}`)

        //console.log(`data to submit: ${JSON.stringify(data)}`);

        return await postStock(data)
    }

    const Submit = async (data: Stock) => {
        const images = state.images;

        Promise.all(_.map(images.filter(image => image.imageId == null), async (image) => {
            image.imageId = await uploadImage(image.file)
        })).then(() => {
            data.imageIds = images.map(image => image.imageId) as string[]

            //console.log(`data.imageIds: ${JSON.stringify(data.imageIds)}`)

            submitStock(data).then(() => {
                history.push('/Admin/Stocks') 
            });
        })
    }

    return (
        <React.Fragment>
           
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item>Admin</Breadcrumb.Item>
                <Breadcrumb.Item>Stocks</Breadcrumb.Item>
                <Breadcrumb.Item>{form.getFieldValue('name_en')}</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ width: '90%', margin: 'auto', marginBottom: '25px'}}>

                <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px'}} className={'ant-card-head-title'}>Stock Details</div>

                <PageLoader loading={isLoadingStock || isLoadingImage} subTitle={'Loading data...'}>
                    <Form
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 14 }}
                        layout="horizontal"
                        form={form}
                        onFinish={Submit}
                    >
                        <Form.Item name='id' hidden={true}>
                            <Input />
                        </Form.Item>

                        <StockCategoriesDropDown categories={state.categories} isLoading={isLoadingCategories} required={true}/>
                        
                        <Form.Item name='name_en' label="Name EN" rules={[{ required: true, max: 100, message: 'Please enter a name! (max. 100 characters)' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='name_nl' label="Name NL" rules={[{ required: true, max: 100, message: 'Please enter a name! (max. 100 characters)' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='name_de' label="Name DE" rules={[{ required: true, max: 100, message: 'Please enter a name! (max. 100 characters)' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='description_en' label="Description EN" rules={[
                            {
                                required: true,
                                validateTrigger: 'onBlur',
                                min: 1,
                                max: 1000,
                                message: 'Please enter a Description EN (between 1 and 1000 characters!)'
                            }
                            ]} >
                            <HtmlEditor height={150} onChange={(editorState) => { form.setFieldsValue({description_en: editorState.toHTML()}) }}/>
                        </Form.Item>

                        <Form.Item name='description_nl' label="Description NL" rules={[
                            {
                                required: true,
                                validateTrigger: 'onBlur',
                                min: 1,
                                max: 1000,
                                message: 'Please enter a Description NL (between 1 and 1000 characters!)'
                            }
                            ]} >
                            <HtmlEditor height={150} onChange={(editorState) => { form.setFieldsValue({description_nl: editorState.toHTML()}) }}/>
                        </Form.Item>

                        <Form.Item name='description_de' label="Description DE" rules={[
                            {
                                required: true,
                                validateTrigger: 'onBlur',
                                min: 1,
                                max: 1000,
                                message: 'Please enter a Description DE (between 1 and 1000 characters!)'
                            }
                            ]} >
                            <HtmlEditor height={150} onChange={(editorState) => { form.setFieldsValue({description_de: editorState.toHTML()}) }}/>
                        </Form.Item>

                        <Form.Item name='yearManufactured' label='Year Manufactured'>
                            <InputNumber min={1900} max={2500} precision={0} maxLength={4} style={{ width: 150 }} />
                        </Form.Item>

                        <Form.Item name='kilometers' label='Kilometers'>
                            <InputNumber min={0} max={999999} precision={0} maxLength={6} style={{ width: 150 }} />
                        </Form.Item>

                        <Form.Item name='wheelbase' label='Wheelbase'>
                            <InputNumber min={0} max={99999} precision={0} maxLength={5} style={{ width: 150 }} />
                        </Form.Item>

                        <Form.Item name='weight' label='Weight'>
                            <InputNumber min={0} max={99999} precision={0} maxLength={5} style={{ width: 150 }} />
                        </Form.Item>

                        <Form.Item name='transmissionType' label='Transmission'>
                            <Select style={{ width: 200 }}>
                                <Select.Option value=''>&nbsp;</Select.Option>
                                <Select.Option key={1} value={1}>Manual</Select.Option>
                                <Select.Option key={2} value={2}>Automatic</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item name='numberOfWheelchairs' label='Number of Wheelchairs'>
                            <InputNumber min={0} max={99} precision={0} maxLength={2} style={{ width: 75 }} />
                        </Form.Item>

                        <Form.Item name='lift_en' label="Lift EN" rules={[
                            {
                                validateTrigger: 'onBlur',
                                max: 1000,
                                message: 'Please enter a Lift EN (between 0 and 1000 characters!)'
                            }
                            ]} >
                            <HtmlEditor height={150} onChange={(editorState) => { form.setFieldsValue({lift_en: editorState.toHTML()}) }}/>
                        </Form.Item>

                        <Form.Item name='lift_nl' label="Lift NL" rules={[
                            {
                                validateTrigger: 'onBlur',
                                max: 1000,
                                message: 'Please enter a Lift NL (between 0 and 1000 characters!)'
                            }
                            ]} >
                            <HtmlEditor height={150} onChange={(editorState) => { form.setFieldsValue({lift_nl: editorState.toHTML()}) }}/>
                        </Form.Item>

                        <Form.Item name='lift_de' label="Lift DE" rules={[
                            {
                                validateTrigger: 'onBlur',
                                max: 1000,
                                message: 'Please enter a Lift DE (between 0 and 1000 characters!)'
                            }
                            ]} >
                            <HtmlEditor height={150} onChange={(editorState) => { form.setFieldsValue({lift_de: editorState.toHTML()}) }}/>
                        </Form.Item>

                        <Form.Item name='entry_en' label="Entry EN" rules={[
                            {
                                validateTrigger: 'onBlur',
                                max: 1000,
                                message: 'Please enter an Entry EN (between 0 and 1000 characters!)'
                            }
                            ]} >
                            <HtmlEditor height={150} onChange={(editorState) => { form.setFieldsValue({entry_en: editorState.toHTML()}) }}/>
                        </Form.Item>

                        <Form.Item name='entry_nl' label="Entry NL" rules={[
                            {
                                validateTrigger: 'onBlur',
                                max: 1000,
                                message: 'Please enter an Entry NL (between 0 and 1000 characters!)'
                            }
                            ]} >
                            <HtmlEditor height={150} onChange={(editorState) => { form.setFieldsValue({entry_nl: editorState.toHTML()}) }}/>
                        </Form.Item>

                        <Form.Item name='entry_de' label="Entry DE" rules={[
                            {
                                validateTrigger: 'onBlur',
                                max: 1000,
                                message: 'Please enter an Entry DE (between 0 and 1000 characters!)'
                            }
                            ]} >
                            <HtmlEditor height={150} onChange={(editorState) => { form.setFieldsValue({entry_de: editorState.toHTML()}) }}/>
                        </Form.Item>

                        <Form.Item name='price' label='Price'> 
                            <InputNumber min={0} max={9999999.99} precision={2} maxLength={9} style={{ width: 200 }} />
                        </Form.Item>

                        <Form.Item name='priceType' label='Price Type'>
                            <Select style={{ width: 200 }}>
                                <Select.Option value=''> </Select.Option>
                                <Select.Option key={1} value={1}>Excluding BPM</Select.Option>
                                <Select.Option key={2} value={2}>Including BPM</Select.Option>
                                <Select.Option key={3} value={3}>No BPM</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item name='visible' label="Visible"  valuePropName="checked">
                            <Checkbox />
                        </Form.Item>

                        <Form.Item name='images' label="Images">
                            <ImagesManager images={state.images} max={3} onChange={onImagesChanged} />
                        </Form.Item>
                                
                    </Form>

                    <Row>
                        <Col span={14} offset={6} style={{ textAlign: 'right'}}>
                            <Space>
                                <Button type='default' icon={<ArrowLeftOutlined />} onClick={() => history.push('/Admin/Stocks')}>{t('common:back')}</Button>
                                <Button type='primary' icon={<SaveOutlined/>} onClick={() => form.submit()}>Save</Button>
                            </Space>
                        </Col>
                    </Row>
                </PageLoader>
                    
            </div>

        </React.Fragment>
    );
})