import BraftEditor, { ControlType, EditorState } from "braft-editor"
import React from "react"

interface Props {
    value?: EditorState,
    height?: number,
    onChange(editorState: EditorState): void
}

export const HtmlEditor = (props: Props) => {
    //const value = BraftEditor.createEditorState(props.value)
    const height = props.height ?? 300;
    const controls = [ 'bold', 'italic', 'underline', 
        'separator', 'list-ul', 'list-ol', 
        'separator', 'link', 
        'separator', 'clear', 'undo', 'redo'] as ControlType[]
        
    return (
        <div className="my-component">
            <BraftEditor
                value={props.value}
                language='en'
                style={{ border: '1px solid #d9d9d9', height: height, overflow: 'hidden'}}
                contentStyle={{ height: height - 45 }}
                controls={controls}
                onChange={(editorState) => { props.onChange(editorState) }}
            />
        </div>
    )
}