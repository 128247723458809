import { Breadcrumb, Button, Col, Form, Input, Row, Select, Space} from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SaveOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { MultiFetcher } from '../../utilities/MultiFetcher';
import { PartCategory } from '../../../models/PartCategory';
import { ParseMlText, StringifyMlText } from '../../utilities/MlTextHelper';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { PageLoader } from '../../utilities/PageLoader';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

interface State {
    rootCategories: PartCategory[];
}

export const PartCategoryEditor = withAuthenticationRequired(() => { 
    const { id } = useParams<{ id: string }>()
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const history = useHistory();

    const [state, setState] = useState<State>({
        rootCategories: []
    });
   
    const {fetchSingle: fetchCategory, postSingle: postCategory, fetchMultiple: fetchCategories, isLoading} = MultiFetcher('partCategory');   
    
    useEffect(() => {
        window.scrollTo(0, 0)      
    
        if (id)
        {
            const fetchData = async () => {
                const category = await fetchCategory({id, disableCache: true}) as PartCategory;

                //console.log(`data: ${JSON.stringify(manual)}`);

                let name = ParseMlText(category.name)
                
                form.setFieldsValue({
                    id: category.id,
                    name_en: name['en'],
                    name_nl: name['nl'],
                    name_de: name['de'],
                    parentId: category.parentId
                });
            }

            fetchData();            
        }

        const fetchRootCategories = async() => {
            const categories = await fetchCategories({disableCache: true}) as PartCategory[];

            //(`rootCategories: ${JSON.stringify(categories.filter(cat => cat.parentId == null))}`)

            setState(prevState => ({ ...prevState, rootCategories: categories.filter(cat => cat.parentId == null) }));
        }

        fetchRootCategories();
        
    }, [fetchCategories, fetchCategory, form, id]);

    const Submit = async (data: PartCategory) => {
            
        data.name = StringifyMlText(
            form.getFieldValue('name_en'), 
            form.getFieldValue('name_nl'),
            form.getFieldValue('name_de')
        )

        //console.log(`data to submit: ${JSON.stringify(data)}`);

        postCategory(data).then(async response => {
            if (response) {
                history.push('/admin/PartCategories');
            }
        });
    }

    return (
        <React.Fragment>
           
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item>Admin</Breadcrumb.Item>
                <Breadcrumb.Item>Part Category</Breadcrumb.Item>
                <Breadcrumb.Item>{form.getFieldValue('name_en')}</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ width: '80%', margin: 'auto', marginBottom: '25px'}}>

                <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px'}} className={'ant-card-head-title'}>Part Category Details</div>

                <PageLoader loading={isLoading} subTitle={'Loading data...'}>
                    <Form
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 14 }}
                        layout="horizontal"
                        form={form}
                        onFinish={Submit}
                    >
                        <Form.Item name='id' hidden={true}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='parentId' label="Parent Category"> 
                            <Select>
                                <Select.Option key='0' value=''>&nbsp;</Select.Option>
                                { _.map(state.rootCategories, (cat, index) => {
                                    return <Select.Option key={`cat_${index}`} value={cat.id}>{ParseMlText(cat.name)['en']}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                        
                        <Form.Item name='name_en' label="Name EN" rules={[{ required: true, max: 100, message: 'Please enter a name! (max. 100 characters)' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='name_nl' label="Name NL" rules={[{ required: true, max: 100, message: 'Please enter a name! (max. 100 characters)' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='name_de' label="Name DE" rules={[{ required: true, max: 100, message: 'Please enter a name! (max. 100 characters)' }]}>
                            <Input />
                        </Form.Item>
                                
                    </Form>

                    <Row>
                        <Col span={14} offset={6} style={{ textAlign: 'right'}}>
                            <Space>
                                <Button type='default' icon={<ArrowLeftOutlined />} onClick={() => history.push('/admin/PartCategories')}>{t('common:back')}</Button>
                                <Button type='primary' icon={<SaveOutlined/>} onClick={() => form.submit()}>Save</Button>
                            </Space>
                        </Col>
                    </Row>
                </PageLoader>
                    
            </div>

        </React.Fragment>
    );
})