import { Breadcrumb, Button, Col, DatePicker, Form, Input, Row, Select, Space } from 'antd';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SaveOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import { MultiFetcher } from '../../utilities/MultiFetcher';
import { useState } from 'react';
import * as _ from 'lodash';
import { Image } from '../../../models/Image';
import { NewsItem } from '../../../models/NewsItem';
import { ParseMlText, StringifyMlText } from '../../utilities/MlTextHelper';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { PageLoader } from '../../utilities/PageLoader';
import { ImagesManager } from '../_common/ImagesManager';
import { useTranslation } from 'react-i18next';
import { HtmlEditor } from '../_common/HtmlEditor';
import BraftEditor from 'braft-editor';

interface State {
    type: number;
    images: Image[]; 
}

export const NewsItemEditor = withAuthenticationRequired(() => { 
    const { id } = useParams<{ id: string }>()
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const history = useHistory();

    const [state, setState] = useState<State>({
        type: 0,
        images: []
    });
   
    const {fetchSingle: fetchNewsItem, postSingle: postNewsItem, isLoading: isLoadingNewsItem} = MultiFetcher('newsitem');   
    const {postFile: uploadImage, isLoading: isLoadingImage} = MultiFetcher('image');   

    useEffect(() => {
        window.scrollTo(0, 0)      
    
        if (id)
        {
            const fetchData = async () => {
                const newsItem = await fetchNewsItem({id, disableCache: true}) as NewsItem;

                //console.log(`data: ${JSON.stringify(newsItem)}`);
                let title = ParseMlText(newsItem.title)
                let description = ParseMlText(newsItem.description)
                
                form.setFieldsValue({
                    id: newsItem.id,
                    type: newsItem.type,
                    date: moment(newsItem.date),
                    title_en: title['en'],
                    title_nl: title['nl'],
                    title_de: title['de'],
                    description_en: BraftEditor.createEditorState(description['en']),
                    description_nl: BraftEditor.createEditorState(description['nl']),
                    description_de: BraftEditor.createEditorState(description['de']),
                    youTubeCode: newsItem.youTubeCode
                });

                //console.log(`imageIds: ${newsItem.imageIds}`)

                let images : Image[] = []

                _.map(newsItem.imageIds, imageId => {
                    images.push({
                        imageId: imageId
                    } as Image)
                })

                //console.log(`images: ${JSON.stringify(images)}`)

                setState(prevState => ({ ...prevState, type: newsItem.type, images }));
            }

            fetchData();
        }
        
    }, [fetchNewsItem, form, id]);
  
    const onImagesChanged = ((images: Image[]) => {
        setState(prevState => ({ ...prevState, images }));
    })

    const submitNewsItem = async (data: NewsItem) => {
        data.title = StringifyMlText(
            form.getFieldValue('title_en'), 
            form.getFieldValue('title_nl'),
            form.getFieldValue('title_de')
        )
            
        data.description = StringifyMlText(
            form.getFieldValue('description_en'), 
            form.getFieldValue('description_nl'),
            form.getFieldValue('description_de')
        )

        //console.log(`data to submit: ${JSON.stringify(data)}`);

        return await postNewsItem(data)
    }

    const Submit = async (data: NewsItem) => {
        if (data.type === 0) {
            const images = state.images;

            Promise.all(_.map(images.filter(image => image.imageId == null), async (image) => {
                image.imageId = await uploadImage(image.file)
            })).then(() => {
                data.imageIds = images.map(image => image.imageId) as string[]

                //console.log(`data.imageIds: ${JSON.stringify(data.imageIds)}`)

                submitNewsItem(data).then(() => {
                    history.push('/admin/newsitems') 
                });
            })
        } else {
            data.imageIds = []

            submitNewsItem(data).then(() => {
                history.push('/admin/newsitems') 
            });
        }
    }

    return (
        <React.Fragment>
           
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item>Admin</Breadcrumb.Item>
                <Breadcrumb.Item>NewsItems</Breadcrumb.Item>
                <Breadcrumb.Item>{form.getFieldValue('title_en')}</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ width: '90%', margin: 'auto', marginBottom: '25px'}}>

                <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px'}} className={'ant-card-head-title'}>NewsItem Details</div>

                <PageLoader loading={isLoadingNewsItem || isLoadingImage} subTitle={'Loading data...'}>
                    <Form
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 14 }}
                        layout="horizontal"
                        form={form}
                        onFinish={Submit}
                    >
                        <Form.Item name='id' hidden={true}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='type' label="Type" rules={[{ required: true, message: 'Please select a type!' }]}> 
                            <Select onChange={(value: any) => setState(prevState => ({ ...prevState, type: value })) }>
                                <Select.Option value={0}>image</Select.Option>
                                <Select.Option value={1}>YouTube</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item name='date' label="Date" rules={[{ required: true, message: 'Please select a date!' }]}>
                            <DatePicker format='DD-MM-YYYY' />
                        </Form.Item>
                        
                        <Form.Item name='title_en' label="Title EN" rules={[{ required: true, max: 100, message: 'Please enter a title! (max. 100 characters)' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='title_nl' label="Title NL" rules={[{ required: true, max: 100, message: 'Please enter a title! (max. 100 characters)' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='title_de' label="Title DE" rules={[{ required: true, max: 100, message: 'Please enter a title! (max. 100 characters)' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='description_en' label="Description EN" rules={[
                            {
                                validateTrigger: 'onBlur',
                                min: 0,
                                max: 1000,
                                message: 'Please enter a Description EN (not more than 1000 characters!)'
                            }
                            ]} >
                            <HtmlEditor height={150} onChange={(editorState) => { form.setFieldsValue({description_en: editorState.toHTML()}) }}/>
                        </Form.Item>

                        <Form.Item name='description_nl' label="Description NL" rules={[
                            {
                                validateTrigger: 'onBlur',
                                min: 0,
                                max: 1000,
                                message: 'Please enter a Description NL (not more than 1000 characters!)'
                            }
                            ]} >
                            <HtmlEditor height={150} onChange={(editorState) => { form.setFieldsValue({description_nl: editorState.toHTML()}) }}/>
                        </Form.Item>

                        <Form.Item name='description_de' label="Description DE" rules={[
                            {
                                validateTrigger: 'onBlur',
                                min: 0,
                                max: 1000,
                                message: 'Please enter a Description DE (not more than 1000 characters!)'
                            }
                            ]} >
                            <HtmlEditor height={150} onChange={(editorState) => { form.setFieldsValue({description_de: editorState.toHTML()}) }}/>
                        </Form.Item>

                        {state.type === 1 ?
                            <Form.Item name='youTubeCode' label="YouTube Code" rules={[{ required: true, pattern: new RegExp('^[A-Za-z0-9]+$'), max: 100, message: 'Please enter a valid YouTube video code! (e.g. GIJK1RjUHRY)' }]}>
                                <Input />
                            </Form.Item> :
                            <Form.Item name='images' label="Images">
                                <ImagesManager images={state.images} max={3} onChange={onImagesChanged} />
                            </Form.Item>
                        }
                                
                    </Form>

                    <Row>
                        <Col span={14} offset={6} style={{ textAlign: 'right'}}>
                            <Space>
                                <Button type='default' icon={<ArrowLeftOutlined />} onClick={() => history.push('/admin/newsitems')}>{t('common:back')}</Button>
                                <Button type='primary' icon={<SaveOutlined/>} onClick={() => form.submit()}>Save</Button>
                            </Space>
                        </Col>
                    </Row>
                </PageLoader>
                    
            </div>

        </React.Fragment>
    );
})