import { Breadcrumb, Col, Row, Space, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { MultiFetcher } from '../../utilities/MultiFetcher';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ParseMlText } from '../../utilities/MlTextHelper';
import { AddButton, DeleteButton, EditButton } from '../_common/CustomButtons';
import { StockCategoriesDropDown } from '../_common/StockCategoriesDropDown';
import { StockCategory } from '../../../models/StockCategory';
import { Stock } from '../../../models/Stock';

interface State {
    stocks: Stock[];
    categories: StockCategory[];
    selectedCategoryId?: string;
}

export const StockList = withAuthenticationRequired(() => { 
    
    const [state, setState] = useState<State>({
        stocks: [],
        categories: [],
        selectedCategoryId: undefined
      });

    const {fetchMultiple: fetchStocks, deleteSingle: deleteStock, isLoading: isLoadingStocks} = MultiFetcher('stock');   
    const {fetchMultiple: fetchCategories, isLoading: isLoadingCategories} = MultiFetcher('stockCategory');   

    const fetchAllCategories = useCallback(async() => {
        const categories = await fetchCategories({disableCache: true}) as StockCategory[];
        setState(prevState => ({ ...prevState, categories }));
        //console.log(`categories: ${JSON.stringify(categories)}`)
    }, [fetchCategories]);

    const fetchAllStocks = useCallback(async () => {
        const queryParams = state.selectedCategoryId ? `categoryId=${state.selectedCategoryId}` : undefined;
        const stocks = await fetchStocks({queryParams, disableCache: true});
        setState(prevState => ({ ...prevState, stocks }));
        //console.log(`data: ${JSON.stringify(Parts)}`); 
    }, [fetchStocks, state.selectedCategoryId]);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchAllCategories();
    }, [fetchAllCategories]);

    useEffect(() => {
        fetchAllStocks();
    }, [fetchAllStocks]);

    const deleteItem = async (id: string) => {
        deleteStock(id).then(async () => await fetchAllStocks());
    }

    const onCategoryChange = (value: string) => {
        setState(prevState => ({ ...prevState, selectedCategoryId: value }));
    }

    return (
        <React.Fragment>
            
            <Breadcrumb style={{ margin: '16px 0', paddingLeft: '50px' }}>
                <Breadcrumb.Item>Admin</Breadcrumb.Item>
                <Breadcrumb.Item>Stock</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ width: '80%', margin: 'auto', marginBottom: '25px'}}>

                <div style={{ width: '100%', textAlign: 'center', marginBottom: '20px'}} className={'ant-card-head-title'}>Stock</div>

                <Row>
                    <Col  
                        xs={{ span: 24 }}
                        sm={{ span: 20 }}
                        md={{ span: 16 }}
                        lg={{ span: 12 }}
                        xl={{ span: 8 }}>
                            <StockCategoriesDropDown categories={state.categories} isLoading={isLoadingCategories} required={false} onChange={onCategoryChange} />
                    </Col>
                </Row>

                <Table 
                    loading={isLoadingStocks}
                    rowKey={'id'}
                    columns={[
                        // {
                        //     title: 'Id',
                        //     dataIndex: 'id',
                        //     key: 'id',
                        // },
                        
                        {
                            title: 'Name',
                            dataIndex: 'name',
                            key: 'name',
                            render: (name) => <span>{ParseMlText(name)['en']}</span>
                        },
                        {
                            title: 'Price',
                            dataIndex: 'price',
                            key: 'price',
                            render: (price) => <span>€ {Intl.NumberFormat('nl-NL').format(price)}</span>
                        },
                        {
                            title: 'Visible',
                            dataIndex: 'visible',
                            key: 'visible',
                            render: (value) => <span>{value === true ? 'Yes' : 'No'}</span>
                        },
                        {
                            title: '# Photos',
                            dataIndex: 'imageIds',
                            key: 'imageIds',
                            render: (imageIds: string[]) => <span>{imageIds?.length ?? 0}</span>
                        },
                        {
                            title: 'Category',
                            dataIndex: 'categoryName',
                            key: 'category',
                            //render: (name, row) => <span>{ParseMlText(name)['en']}</span>
                            render: (name, row) => <span>{ParseMlText(name)['en']}</span>,
                            className: state.selectedCategoryId ? 'hide' : 'show'

                        },
                        {
                            key: 'actions',
                            align: 'right',
                            render: (record: any) => 
                                <Space>
                                    <EditButton url={`/Admin/Stock/${record.id}`} />
                                    <DeleteButton id={record.id} onDelete={deleteItem} />
                                </Space>
                        }
                            
                    ]} 
                    dataSource={state.stocks} />
                <Space>
                    <AddButton url='/Admin/Stock/' />
                </Space>

            </div>

        </React.Fragment>
    );
})