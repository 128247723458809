import { Button, Result } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const Status404 = () => {
  const history = useHistory();
  return (
    <React.Fragment>
      <Result
        status={404}
        title='Not found'
        subTitle='Sorry, the resource you are looking for is not found.'
        extra={<Button type='primary' onClick={() => history.push('/')} >Back Home</Button>}
      />
    </React.Fragment>
  );
};
