import React from 'react';
import { Card, Col, Image, Row } from 'antd';

interface Props {
    image: string;
    title?: string;
    children: React.ReactNode;
}

export const CardImageLeft = (props: Props) => {
    return (
        <React.Fragment>
            <Row>
                <Col 
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 10, offset: 2 }}
                    xl={{ span: 8, offset: 4 }}>
                    <Card
                        bordered={false}
                        className='card card-double'
                        style={{ margin: 'auto' }}>
                        <Image src={props.image} style={{ maxWidth: '600px', cursor: 'pointer' }} className='ant-card-cover' loading='lazy' />
                    </Card>
                </Col>
                <Col 
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 10 }}
                    xl={{ span: 8 }}>
                    <Card
                        bordered={false}
                        className='card card-double'
                        title={props.title}
                        style={{ margin: 'auto', padding: '10px' }}>
                            {props.children}
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}