import { Button, Card, Popconfirm, Upload } from "antd";
import React, { ReactNode } from "react";
import { DeleteOutlined, PlusOutlined, DragOutlined } from '@ant-design/icons';
import { Image } from "../../../models/Image";
import { ImageFromBlob } from "./ImageFromBlob";
import ImgCrop from "antd-img-crop";
import * as _ from 'lodash';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from "array-move";
import { ImageFromId } from "../../utilities/ImageFromId";

interface Props {
    images: Image[]
    max: number
    onChange(images: Image[]): void
}

interface SortItemProps {
    sortIndex: number
    value: Image
}

interface SortContainerProps {
    children: ReactNode[]
}

export const ImagesManager = (props: Props) => {

    const SortItem = SortableElement((extraProps: SortItemProps) => {
        return (
            <Card
                key={extraProps.sortIndex}
                style={{ width: '200px', marginRight: '16px', marginBottom: '16px'}}
                bodyStyle={{ padding: 0}}
                actions={[
                    <DragHandle />,
                    <Popconfirm
                        title='Are you sure you want to delete this image?'
                        onCancel={(e) => e?.stopPropagation()}
                        onConfirm={(e) => {
                            e?.stopPropagation();
                            deleteImage(extraProps.sortIndex)
                        }}>
                        <Button danger size='small' icon={<DeleteOutlined />} title='Delete Image' onClick={e => e.stopPropagation()} />
                    </Popconfirm>
                ]}>
                { extraProps.value.imageId != null ?
                    <ImageFromId imageId={extraProps.value.imageId} width={200} preview={true} />
                    :   
                    <ImageFromBlob blob={extraProps.value.file} />
                }
            </Card>
        )
    })

    const DragHandle = SortableHandle(() => <Button size='small' icon={<DragOutlined />} title='Move Image' style={{ cursor: 'move' }} />);

    const Container = SortableContainer((extraProps: SortContainerProps) => {
        return <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>{extraProps.children}</div>
    })

    interface SortEndExtraProps {
        oldIndex: number
        newIndex: number
    }

    const onSortEnd = (extraProps: SortEndExtraProps) => {
        const images = arrayMove(props.images, extraProps.oldIndex, extraProps.newIndex)
        props.onChange(images)
    };

    const deleteImage = (index: number) => {
        let images = props.images.slice(0)
        images.splice(index, 1);
        props.onChange(images)
    }

    const customUploadRequest = (options: UploadRequestOption<any>) => {
        let images = props.images.slice(0);

        images.push({
            file: options.file
        } as Image)

        props.onChange(images)
    }

    return (
        <React.Fragment>
             <Container axis={'xy'} useDragHandle onSortEnd={onSortEnd}>
                {_.map(props.images, (image, index) => {
                    return (
                        <SortItem key={`item-${index}`} index={index} sortIndex={index} value={image} />
                    )
                })} 

                { props.images.length < props.max ?
               
                <div style={{width: '200px', height: 208, marginRight: 9, marginBottom: 9 }}>
                    <ImgCrop zoom aspect={1.5}>
                        <Upload 
                            customRequest={customUploadRequest} 
                            showUploadList={false} 
                            listType="picture-card"
                            accept='image/*'
                            style={{ width: 200, height: 208, padding: 0 }}
                            >
                            <PlusOutlined />
                        </Upload>
                    </ImgCrop>
               
                </div>
            : null }
            </Container>   
        </React.Fragment>
    )
}