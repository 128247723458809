import { PriceType, TransmissionType } from "../../models/Stock"

export const GetTransmissionTypeDisplayName = (type: TransmissionType | undefined) : string => {
    switch (type) {
        case 1: return "Manual"
        case 2: return "Automatic"
        default: return "n/a"
    }
}

export const  GetPriceTypeDisplayName = (type: PriceType | undefined) : string =>  {
    switch (type) {
        case 1: return "Excl. BPM"
        case 2: return "Incl. BPM"
        case 3: return "No BPM"
        default: return "n/a"
    }
}