import { Button, Result } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const Status500 = () => {
  const history = useHistory();
  return (
    <React.Fragment>
      <Result
        status={500}
        title='Oops...'
        subTitle='Something went wrong.'
        extra={<Button type='primary' onClick={() => history.push('/')} >Back Home</Button>}
      />
    </React.Fragment>
  );
};
